.venuse-box {
  margin-bottom: 20px;
}

.venueSec {
  padding: 0 30px;

  .card {
    padding-bottom: 15px;
    cursor: pointer;
    border-radius: inherit;

    h5.card-title {
      font-size: 19px;
      font-weight: 700;
    }

    .card-body {
      text-align: center;

      .card-text:last-child {
        margin-bottom: 0;
        max-height: 55px;
        min-height: 55px;
        font-size: 12px;
        overflow: hidden;
      }
    }

    img.card-img-top {
      padding: 20px;
      height: 185px;
    }
  }

  .fearture-btn {
    margin-top: 15px;
    text-align: center;

    a.events.btn-btn {
      text-decoration: none;
      border: 1px solid;
      padding: 8px 5px 8px 5px;
      background-color: #ff2851;
      color: white;

      span.button-cnt {
        font-weight: 600;
      }
    }

    span.package {
      outline: 1px solid;
      padding: 4px 9px;
      color: #fff;

      img {
        position: relative;
        top: -2px;
        left: -4px;
      }
    }
  }
}

.events.btn-btn {
  width: max-content;
  margin: auto;
  background-color: #ff2851;
  padding: 10px;
}

.btn-cnt-active {
  width: max-content;
  margin: auto;
  background-color: #dfdfdf;
  padding: 10px;

  .package {
    outline: 1px solid #fff;
    color: #fff;
  }
}

@media (min-width: 300px) and (max-width: 480px) {
  span.button-cnt {
    display: none;
  }

  .fearture-btn {
    margin-top: 7px;
  }

  .fearture-btn a.events.btn-btn {
    text-decoration: none;
    pointer-events: none;
    padding: 3px 5px 8px 5px;

    span.package {
      padding: 1px;
      padding-left: 9px;
      padding-right: 9px;
    }
  }

  .venueSec {
    padding: 0 20px;
  }
  .venueSec .fearture-btn span.package img {
    position: relative;
    top: -3px;
    left: 0px;
  }
}

@media (min-width: 480px) and (max-width: 735px) {
  .venueSec {
    padding: 0 20px;
  }

  a.events.btn-btn {
    padding: 8px 3px 8px 3px;
  }

  span.package {
    padding: 4px 14px 4px 14px;
  }

  .fearture-btn {
    margin-top: 20px !important;
  }
}

@media (min-width: 735px) and (max-width: 979px) {
}

@media (min-width: 768px) {
  .venueSec {
    img.card-img-top {
      padding: 10px !important;
      width: auto;
      object-fit: cover;
    }

    .card-body {
      padding: 7px;
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .venueSec .card {
    h5.card-title {
      min-height: 46px;
    }

    p.card-text {
      min-height: 40px;
    }
  }
}

.feature-pointer {
  cursor: pointer;
}
