.homeBannerChild {
  .container {
    padding: 0;
  }

  .addBanner {
    padding-bottom: 31px;

    .react-dropdown-select {
      padding: 8px 10px;
      .css-1yc4zyy-DropdownHandleComponent {
        margin: 0 0 0 5px !important;
      }
      .css-1aarvou-DropdownHandleComponent {
        margin: 0 0 0 5px !important;
      }
    }

    .react-dropdown-select:hover,
    :focus {
      border-color: #ccc;
      box-shadow: none;
    }
    .css-t3ipsp-control {
      border-color: #ccc;
      box-shadow: none;
    }
  }

  .banners-title-Heading {
    padding: 10px 0 !important;
  }

  .create-customer {
    padding-top: 10px;

    label.customer-name {
      padding: 10px 0 !important;
    }

    input.banner-names {
      width: 100%;
      padding: 8px 10px;
      border: 1px solid lightgray;
    }

    .input-boxes {
      margin-top: 16px;
      display: flex;

      input.banners-date {
        width: 100%;
        padding: 8px 10px;
        border: 1px solid lightgray;
      }

      input.banners-links {
        margin-left: 15px;
        width: 100%;
        padding: 8px 10px;
        border: 1px solid lightgray;
      }
    }

    .text-area-banner {
      margin-top: 17px;

      textarea.form-control {
        padding-top: 13px !important;
        border-radius: inherit;
      }

      textarea.form-control:focus {
        box-shadow: none;
        border-color: lightgray;
      }
    }
  }

  .images-areas {
    padding-top: 30px;

    img.bannner-img {
      width: 100%;
      height: 185px;
    }

    button.uploadbtn {
      width: 100%;
      font-family: "Roboto Condensed";
      padding: 10px 10px 8px 10px;
      border: none;
      background-color: #ff2851;
      color: white;
      font-size: 18px;
      margin-top: 20px;
      font-weight: 600;
    }
  }
}

input.Banner-base {
  width: 100%;
  padding: 8px 10px;
}

@media (min-width: 300px) and (max-width: 480px) {
  .input-boxes {
    display: block !important;
  }
  .banners-links {
    margin-left: 0 !important;
    margin-top: 10px;
  }
}

@media (min-width: 480px) and (max-width: 768px) {
}

@media (min-width: 768px) and (max-width: 979px) {
}

@media (min-width: 979px) and (max-width: 1280px) {
}

@media (min-width: 1280px) and (max-width: 1411px) {
  .input-boxes {
    display: flex;
  }
}
