.sponsor-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
  .sponsor {
    margin-bottom: 15px;
  }
  .card-sponsor {
    border: 1px solid #e9e8e8;
    background-color: white;
  }

  img.card-img-top {
    border-bottom: 1px solid #e9e8e8;
    padding: 15px;
    height: 200px;
    cursor: pointer;
  }
  .card-body {
    text-align: center;
    padding: 15px 0;
  }
  a.card-text {
    text-decoration: none;
    color: black;
    font-weight: 700;
    font-size: 20px;
  }
}
@media (min-width: 300px) and (max-width: 480px) {
  .sponsor-container {
    width: calc(100% - 0px);
    top: 100px;
    justify-content: center;
    .sponsor {
      margin-top: 25px;
    }
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .sponsor-container {
    justify-content: center;
    top: 125px;
    .sponsor {
      margin: 20px 0;
    }
  }
}
@media (min-width: 735px) and (max-width: 979px) {
  .sponsor-container {
    width: calc(100% - 0px);
    .card-body {
      padding: 10px 0;
    }
  }
}
@media (min-width: 979px) and (max-width: 1280px) {
  .sponsor-container {
    top: 100px;
    .sponsor {
      .card-sponsor {
        width: auto;
      }
    }
  }
}
