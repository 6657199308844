.Jobs-container {
  position: relative;
  top: 100px;
  width: 90% !important;
  margin: auto;

  .jobs-Box {
    border: 1px solid #e9e8e8;
    padding: 10px 20px;
    background-color: white;
    margin-bottom: 12px;

    ul.jobs-content-item {
      list-style-type: none;
      padding-left: 0px;
      margin-bottom: 0;

      li.jobs-listing {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        align-items: center;

        a.Jobs-Feadback {
          text-decoration: none;
          color: black;

          p.jobs-name {
            font-weight: 600;
            font-size: 22px;
            font-family: "Roboto Condensed";
            margin-bottom: 0;
          }

          p.jobs-Description {
            font-family: Lato;
            font-size: 12px;
          }
        }

        .jobs-btns {
          display: flex;

          .jobs-Disable-btn {
            border: 1px solid lightgray;
            padding: 4px 6px;
            background-color: #ff2851;
            color: white;
            width: max-content;

            .jobs-buttons {
              outline: 1px solid;
              padding: 2px 10px;
            }

            span.button-jobs-Disable {
              margin-left: 4px;
            }
          }

          .jobs-Delete-btn {
            border: 1px solid lightgray;
            padding: 4px 6px;
            background-color: #ff2851;
            color: white;
            margin-left: 10px;
            width: max-content;

            .jobs-buttons {
              outline: 1px solid;
              padding: 2px 10px;
            }

            span.button-jobs-Delete {
              margin-left: 4px;
            }
          }
        }
      }
    }
  }
}

.jobs-Applicent-btn {
  border: 1px solid lightgray;
  padding: 6px;
  background-color: #ff2851;
  color: white;
  margin-right: 10px;
  width: max-content;

  .jobs-buttons {
    width: max-content;
  }
}

.jobs-Applicent {
  outline: 1px solid;
  padding: 3px 10px 4px;
}

.draftClr {
  opacity: 0.4;
}

@media (min-width: 300px) and (max-width: 480px) {
  .Jobs-container {
    top: 137px;

    .jobs-Box {
      max-height: 200px;
      overflow: auto;
    }

    span.button-jobs-Disable {
      display: none;
    }

    span.button-jobs-Delete {
      display: none;
    }
  }
}

@media (min-width: 480px) and (max-width: 735px) {
  .Jobs-container {
    top: 150px;

    span.button-jobs-Disable {
      display: none;
    }

    span.button-jobs-Delete {
      display: none;
    }
  }
}

@media (min-width: 735px) and (max-width: 979px) {
  .Jobs-container {
    top: 160px;
  }
}

.disable-no-applicient {
  cursor: not-allowed !important;
}

.jobSelector {
  width: 100%;
  padding: 8px 10px;
  margin-bottom: 25px;

  &:focus-visible {
    outline: 0;
  }
}