.Cities {
  margin: 0px 30px;

  .Cities-Box {
    border: 1px solid #e9e8e8;
    padding: 28px 0px 13px 0px;
    background-color: white;
    margin-bottom: 12px;

    ul.content-item {
      list-style: none;
    }

    li.CitiesSec {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
    }

    .title {
      line-height: 20px;
    }

    .content-Cities {
      display: flex;
      align-items: center;
    }

    a.include-parking {
      text-decoration: none;
      color: black;
      font-size: 18px;
    }

    span.cities-name {
      font-weight: 600;
      font-size: 22px;
      font-family: "Roboto Condensed";
    }

    span.place {
      display: block;
      font-family: Lato;
      font-size: 12px;
    }

    button.delete-btn {
      border: none;
      background-color: white;
      margin-right: 40px;
    }

    a.Delete-Cities {
      text-decoration: none;
      color: #ff2851;
    }

    span.delete-team-member {
      font-size: 13px;
      font-weight: 600;
      margin-left: 7px;
    }
  }
}

@media (min-width: 300px) and (max-width: 480px) {
  .Cities-container {
    top: 100px;
    width: calc(100% - 0px);
  }

  .Cities {
    margin: 35px 20px 12px 19px;

    ul.content-item {
      padding-left: 9px;
    }

    span.delete-team-member {
      display: none;
    }

    button.delete-btn {
      margin-right: 8px !important;
    }

    .heading-content-title {
      margin-left: 16px !important;
    }
  }
}

@media (min-width: 480px) and (max-width: 735px) {
  .Cities {
    margin: 10px;

    ul.content-item {
      padding-left: 9px;
    }

    span.delete-team-member {
      display: none;
    }

    button.delete-btn {
      margin-right: 8px !important;
    }

    .heading-content-title {
      margin-left: 16px !important;
    }
  }
}

@media (min-width: 735px) and (max-width: 979px) {
}

@media (min-width: 979px) and (max-width: 1280px) {
}
