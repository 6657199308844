.accordion-headerdrop-down {
  width: 100%;
  display: flex;
  align-items: center;
}

.NoData_margin {
  margin: 15px 0;
}

.events-Child {
  padding: 0 20px;
}

span.grnt {
  color: white;
  font-weight: 600;
}

img.concerts-img {
  width: 100%;
}

img.seating-images {
  width: 100%;
}

button#nav-home-tab {
  background-color: #e2e2e2;
  border-radius: unset;
  color: black;
  border-bottom: 1px solid transparent !important;
  font-weight: 600 !important;
}

button#nav-profile-tab {
  background-color: #e2e2e2;
  border-radius: unset;
  color: black;
  font-weight: 600;
}

.active[aria-selected="true"] {
  color: #000 !important;
  background-color: #f6f6f6 !important;
  position: relative;
  top: 0px;
}

div#nav-tab {
  border-bottom: 1px solid grey;

  .nav-link[aria-selected="true"] {
    border-bottom: 1px solid transparent !important;
  }

  .nav-link.active {
    margin-top: -10px;
    padding-top: 18px;
    border: 1px solid grey;
    border-bottom: 0;
    border-left: 1px solid grey !important;
  }

  .nav-link {
    border: 1px solid grey !important;
  }
}

button#nav-contact-tab {
  background-color: #e2e2e2;
  border-radius: unset;
  color: black;
  font-weight: 600;
}

button#nav-media-tab {
  background-color: #e2e2e2;
  border-radius: unset;
  color: black;
  font-weight: 600;
}

button#nav-terms-tab {
  background-color: #e2e2e2;
  border-radius: unset;
  color: black;
  font-weight: 600;
}

.event-Genral-info {
  padding: 34px 35px 0px 0px;

  .inputField {
    position: relative;
    padding: 10px 0px 10px 0px;

    input.genral-Ryan {
      width: 100%;
      padding: 10px 0px 10px 10px;
      border: 1px solid lightgray;
    }

    span.genr-btn {
      position: absolute;
      right: 0;
      border: 1px solid black;
      margin-top: 3px;
      padding: 7px 4px 9px 4px;
      margin-right: 4px;
      border: none;

      span.rayn-btn {
        outline: 1px solid white;
        padding: 4px 8px 8px 10px;
        text-decoration: none;

        span.featu#ff2851 {
          color: white;
          font-weight: 600;
        }
      }
    }

    .activeFeature {
      background-color: #ff2851;
    }

    .deactiveFeature {
      background-color: #dfdfdf;
    }
  }

  .Subtitle {
    padding-top: 20px;
  }

  .booking-event {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .reserve-date-time {
      width: 100%;
      margin-right: 5px;
      display: flex;

      input {
        width: 100%;
        padding: 8px;
      }
    }

    .event-end {
      width: 100%;
      margin-left: 5px;
      display: flex;

      input {
        width: 100%;
        padding: 8px;
      }
    }

    .timesanddate {
      display: flex;
      margin-top: 10px;
    }

    input.months {
      padding: 9px;
      margin-right: 20px;
    }

    input.time {
      padding: 9px;
    }

    .evntsEndtime {
      display: flex;
      margin-top: 10px;
    }
  }

  .door-time {
    margin-top: 15px;
    width: 50%;

    .input-time {
      margin-right: 5px;

      input {
        width: 100%;
      }

      input.Door-time {
        padding: 9px;
      }
    }
  }

  .seo-concert {
    margin-top: 14px;

    .seoInputField {
      margin-top: 10px;
    }

    .seo-input {
      input.seo-name-concert {
        width: 100%;
        padding: 8px;
      }
    }
  }

  .venue {
    margin-top: 8px;

    .css-13cymwt-control {
      border-radius: 0;
      padding: 2px;
    }

    .css-t3ipsp-control:focus {
      box-shadow: none;
      border-color: lightgrey;
    }

    .venue-input {
      margin-top: 9px;

      input.venue-address {
        width: 100%;
        padding: 7px;
      }
    }
  }

  .Description {
    margin-top: 8px;
  }

  label.description-online-editer {
    color: black;
  }
}

.calenderIcon {
  background-image: url("../../Assets/images/ic-calendar.png");
  background-repeat: no-repeat;
  background-position: 95% 13px;
}

.timeIcon {
  background-image: url("../../Assets/images/watch_icon.png");
  background-repeat: no-repeat;
  background-position: 95% 13px;
}

.rightSide-part {
  padding-top: 41px;

  button.uploadbtn {
    width: 100%;
    margin-top: 15px;
    padding: 11px;
    color: white;
    background-color: #ff2851;
    border: none;
    font-size: 17px;
    font-weight: 600;
    border-radius: unset;
  }

  button.uploadbtn:focus {
    background-color: #ff2851;
  }

  .seating-chart {
    margin-top: 20px;
    background-color: white;
    color: black;
    padding: 14px 15px 14px 15px;
    border: none;

    span.select-seating {
      font-size: 20px;
      font-weight: 600;
    }
  }

  .genral {
    background-color: #f6f6f6;
    padding: 12px;
  }

  .activeSitting {
    border: 3px solid #ff2851;
  }
}

.control-pane {
  margin: 0 10px;
}

.tickets-bookings {
  padding: 34px 35px 0px 0px;

  .book {
    display: flex;
    justify-content: space-between;

    .reserve-date-time {
      width: 100%;
      margin-right: 5px;
      display: flex;

      .control-pane {
        margin: 0 10px;
      }

      input {
        width: 100%;
        padding: 8px;
      }
    }

    .cut-off {
      width: 100%;
      margin-left: 5px;
      display: flex;

      input {
        width: 100%;
        padding: 8px;
      }
    }

    .onsalestart {
      display: flex;
    }

    span.onsale {
      color: black;
      font-size: 17px;
      font-weight: 600;
      padding: 10px;
    }

    input.choose-month {
      margin-right: 20px;
      padding: 8px 10px 8px 10px;
    }

    input.choose-time {
      padding: 8px 10px 8px 10px;
    }

    span.head-cutoff {
      color: black;
      font-size: 17px;
      font-weight: 600;
      padding: 10px;
    }

    .cutoffstarts {
      display: flex;

      input.choose-months {
        margin-right: 20px;
        padding: 8px 10px 8px 10px;
      }
    }
  }

  .generic {
    padding-top: 20px;

    label.genralTic {
      color: black;
      font-size: 17px;
      font-weight: 600;
      padding: 10px;
    }

    input.link {
      width: 100%;
      padding: 8px;
    }
  }

  .add-tickets {
    padding-top: 30px;

    button.addd-button {
      color: white;
      background-color: #ff2851;
      border: none;
      padding: 8px 14px;
      font-size: 17px;
      font-weight: 600;
    }

    .hrline {
      width: 76%;
      display: inline-block;
      border: 1px solid lightgray;
      margin-left: 14px;
    }
  }

  .bookingsandpayments {
    padding-top: 30px;

    button.delete-btn {
      border: none;
      width: max-content;
      background-color: #f6f6f6;
      display: flex;
      align-items: center;
    }

    input#Visa {
      width: auto;
      margin-right: 12px;
    }

    .bookings-box {
      border: 1px solid lightgray;
      background-color: white;
      color: black;

      input {
        padding: 8px;
        width: 100%;
      }

      .tickets-name {
        padding: 20px 17px 20px 17px;
      }

      label.nickname {
        padding: 8px;
        font-weight: 600;
      }

      input.textpart {
        width: 100%;
        padding: 7px;
      }

      .tic-links {
        padding: 20px 17px 20px 17px;

        label.nicklinks {
          font-weight: 600;
          padding: 9px;
        }

        input.textpartition {
          width: 100%;
          padding: 7px;
        }
      }

      .price {
        padding: 20px 17px 20px 17px;

        input {
          width: 25%;
        }

        label.price-tag {
          padding: 9px;
          font-weight: 600;
        }

        input.enter-price {
          padding: 7px;
        }
      }
    }
  }

  img.downArrow {
    width: 13px;
  }

  .change-price {
    display: flex;
    justify-content: space-between;
    .changedPrice {
      margin: 0 10px;
    }
  }

  button.accordion-button.change.collapsed {
    font-size: 13px;
    padding: 9px;
    font-weight: 600;
  }

  img.fa-solid.fa-trash-can {
    margin-right: 4px;
  }

  button.delete-btn-tickets {
    border: none;
    background-color: transparent;
    width: max-content;

    a.Delete-tickets {
      text-decoration: none;
      color: red;
      font-size: 13px;
      font-weight: 600;
    }

    span.Delete-tickets {
      text-decoration: none;
      color: red;
      font-size: 13px;
      font-weight: 600;
    }
  }

  label.changepricetitle {
    font-size: 14px;
    font-weight: 600;
    padding: 6px;
  }

  input.price-presale {
    padding: 8px 10px 8px 10px;
    width: 75%;
  }

  label.after-heading {
    font-size: 14px;
    font-weight: 600;
    padding: 6px;
  }

  input.after-price-month {
    padding: 8px 10px 8px 10px;
    width: 50%;
  }

  input.pricing-time {
    width: 45%;
    padding: 8px 10px 8px 10px;
    margin-left: 12px;
  }

  .startPresale {
    display: flex;
    justify-content: space-between;
    padding: 16px 0;

    .startPresale-content {
      width: 100%;
      margin-right: 10px;
    }

    .EndPresale-content {
      margin-right: 10px;
      width: 100%;
    }

    label.pre-sale-title {
      font-size: 14px;
      font-weight: 600;
      padding: 9px;
    }

    input.presale-month {
      padding: 8px 10px 8px 10px;
    }

    input.presale-time {
      padding: 8px 10px 8px 10px;
      margin-left: 10px;
    }

    .Endsale-start {
      font-size: 13px;
      font-weight: 600;
    }

    input.Endsale-month {
      padding: 8px 10px 8px 10px;
    }

    input.Endsale-time {
      padding: 8px 10px 8px 10px;
      margin-left: 10px;
    }

    label.End-sale-title {
      padding: 9px;
    }

    .content-radio-active {
      display: flex;
    }
  }

  .sponsess-inputs {
    display: flex;
    justify-content: space-between;

    .sponsers-presale-title {
      width: 100%;
      margin-right: 10px;

      input.prespon-search {
        padding: 8px;
        width: 100%;
      }

      input.prespon-promo {
        padding: 8px;
        width: 100%;
      }
    }

    label.pre-spnser {
      font-size: 14px;
      font-weight: 600;
      padding: 9px;
    }
  }

  .content-radio-active {
    display: flex;
  }

  .form-group label {
    position: relative;
    cursor: pointer;
  }

  .form-group label:before {
    border: 2px solid black;
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    background-color: white;
  }

  .presale-ticket {
    padding-top: 20px;

    .form-group {
      display: flex;
    }
  }
}

.charts-seating {
  padding-top: 25px;

  .setings {
    border: 1px solid lightgray;
    background-color: white;
    color: black;
    padding: 15px;

    img.seat-charts {
      width: 100%;
    }

    span.seat-name {
      padding: 10px;
      font-size: 18px;
      font-weight: 700;
    }
  }

  .type-of-payments {
    padding-top: 30px;

    .pymnt {
      border: 1px solid lightgray;
      background-color: white;
      color: black;
      padding: 16px;

      h3.accepts {
        font-size: 19px;
        font-family: "Roboto", sans-serif;
        font-weight: 500;
      }

      .visa {
        gap: 5px;
        display: grid;

        span {
          font-family: "Roboto", sans-serif;
        }
      }
    }
  }
}

.addPakages-btn {
  margin-top: 40px;

  .AddSec {
    border: 1px solid lightgray;
    padding: 20px;
    margin-top: 30px;

    input.SearchField {
      width: 100%;
      padding: 8px 10px;
    }
  }

  button.addpack {
    background-color: #ff2851;
    color: white;
    border: none;
    padding: 11px;
    font-weight: 600;
  }

  .hrlines {
    width: 77%;
    display: inline-block;
    border: 1px solid lightgray;
    margin-left: 14px;
  }
}

button.addpack {
  background-color: #ff2851;
  color: white;
  border: none;
  padding: 11px;
  font-weight: 600;
}

.media {
  padding-top: 34px;

  .disclosiv {
    display: flex;
  }

  .hrline {
    width: 87%;
    border-top: 2px solid lightgray;
    margin-left: 14px;
    position: relative;
    top: 20px;
  }

  .media-boxes {
    margin-top: 20px;
    border: 1px solid lightgray;
    color: black;
    background-color: white;
  }

  .media-content {
    padding: 18px;
  }

  .typing-area {
    padding: 20px;
  }

  span.iconic {
    padding: 10px;
    font-size: 15px;
    color: #000;
    font-weight: bold;
  }

  .media-icons {
    padding-top: 25px;
    margin-left: 20px;

    .links-title-media {
      display: flex;
    }
  }

  input.title-field {
    width: 100%;
  }

  .deletes-btns {
    background-color: #f6f6f6;
    padding: 23px;
    text-align: right;

    button.dele-btn {
      border: none;
      background-color: #f6f6f6;
      color: #ff2851;

      img {
        position: relative;
        top: -2px;
        left: -7px;
      }

      a.Delete-Media {
        color: #ff2851;
        text-decoration: none;

        img.fa-solid.fa-trash-can {
          margin-right: 5px;
        }

        span.delete-medias-section {
          font-size: 15px;
          font-weight: 600;
        }
      }
    }
  }
}

.selection-items {
  padding-top: 30px;

  select.selected-points {
    width: 100%;
    padding: 10px;
    background-color: #fff;
    color: #000;
    border-radius: 0;
    height: 45px;
  }

  select.selected-points:focus-visible {
    outline: 0;
  }
}

.link-names-title {
  width: 50%;
  padding: 13px;
}

.links-title-media {
  display: flex;

  .link-names-title {
    padding: 13px;
    width: 100%;

    input {
      width: 100%;
      padding: 8px;
    }
  }
}

.textEditer-area {
  padding-top: 15px;

  .rule {
    padding: 5px;
    color: black;
    font-size: 14px;
    font-weight: 600;
  }

  .save-as-templet-btn {
    margin-top: 15px;
    margin-bottom: 20px;

    button.save-template {
      border: none;
      background-color: transparent;
      font-size: 14px;
      font-weight: 600;

      span.as-a {
        margin-left: 8px;
        color: #4cb8dc;
      }
    }
  }
}

.accordion-item {
  border: none;
  margin: 0;
}

div#collapseTwo {
  background-color: rgb(246, 246, 246);
}

.accordion-item {
  background-color: rgb(246, 246, 246);
  padding: 10px;
}

.general {
  margin-bottom: 15px;
}

@media (min-width: 280px) and (max-width: 480px) {
  .event-Genral-info .inputField {
    span.genr-btn {
      padding: 6px 5px 7px 5px;

      span.rayn-btn {
        padding: 2px 4px 4px 5px;

        img {
          position: relative;
          top: -2px;
        }
      }
    }
  }

  .EventSec {
    margin-top: 40px;
  }

  .events-Child {
    width: calc(100% - 0px);
    padding-left: 10px;
    padding-right: 10px;

    span.grnt {
      display: none;
    }

    div#nav-tab {
      flex-direction: column;
    }

    .event-Genral-info {
      padding: 20px 0px 0px 0px;

      .door-time {
        width: 100% !important;
      }

      .inputField {
        padding: 2px 0px 10px 0px;
      }
    }

    .Subtitle {
      padding-top: 10px !important;

      .textArea {
        margin-top: 2px !important;
      }
    }

    .booking-event {
      display: block !important;
      margin-top: 15px;

      .timesanddate {
        display: block !important;
        margin-top: 2px;

        input.months {
          margin-right: 0;
          width: 100%;
        }

        input.time {
          margin-top: 12px;
          width: 100%;
        }
      }

      .event-end {
        margin-top: 12px;
        margin-left: 0;

        .evntsEndtime {
          display: block !important;
          margin-top: 2px;

          input.time {
            margin-top: 12px;
            width: 100%;
          }

          input.months {
            margin-right: 0;
            width: 100%;
          }
        }
      }
    }

    .input-time {
      margin-top: 2px !important;
    }

    input.Door-time {
      width: max-content !important;
      color: #000;
      background-color: #fff;
      height: 40px;
    }

    .seo-input {
      margin-top: 7px !important;
    }

    .venue-input {
      margin-top: 2px !important;
    }

    span.select-seating {
      font-size: 17px !important;
    }

    .book {
      display: block !important;

      span.onsale {
        font-size: 14px !important;
        padding: 4px !important;
      }

      .onsalestart {
        display: block !important;

        input.choose-month {
          margin-right: 0;
          width: 100%;
        }

        input.choose-time {
          width: 100%;
          margin-top: 12px;
        }
      }

      .cut-off {
        margin-top: 12px;
        margin-left: 0;

        span.head-cutoff {
          font-size: 14px !important;
          padding: 4px !important;
        }

        .cutoffstarts {
          display: block !important;
          margin: 0;

          input.choose-months {
            margin-right: 0;
            width: 100%;
          }

          input.choose-time {
            margin-top: 11px;
            width: 100%;
          }
        }
      }
    }

    .tickets-bookings {
      padding: 25px 0px 0px 0px;
    }

    label.genralTic {
      font-size: 14px !important;
      padding: 4px !important;
    }

    button.addd-button {
      width: 100%;
    }

    .hrline {
      display: none !important;
    }

    label.nickname {
      font-size: 14px;
      padding: 4px !important;
    }

    label.nicklinks {
      font-size: 14px;
      padding: 4px !important;
    }

    label.price-tag {
      font-size: 14px;
      padding: 4px !important;
    }

    input.enter-price {
      width: 100%;
    }

    span.seat-name {
      font-size: 16px !important;
      padding: 14px !important;
    }

    h3.accepts {
      font-size: 16px !important;
    }

    span.cards {
      font-size: 14px;
    }

    span.Mastercard {
      font-size: 14px;
    }

    span.AmericanExpress {
      font-size: 14px;
    }

    span.Discover {
      font-size: 14px;
    }

    span.cash {
      font-size: 14px;
    }

    .addPakages-btn {
      padding: 34px 0px 0px 0px;

      button.addpack {
        width: 100%;
      }

      .hrlines {
        display: none !important;
      }
    }

    .media {
      padding-top: 30px;

      button.addpack {
        width: 100%;
      }

      .media-content {
        padding: 10px !important;
      }

      .typing-area {
        padding: 10px !important;
      }

      span.iconic {
        font-weight: 600;
      }

      label.titles-contents {
        font-size: 14px;
      }

      span.delete-medias-section {
        display: none;
      }
    }

    .selection-items {
      padding: 35px 0px 0px 1px;
    }
  }

  button.accordion-button {
    font-size: 12px;
  }

  span.delete-team-member {
    display: none;
  }

  .change-price {
    display: block !important;

    label.changepricetitle {
      font-size: 12px !important;
    }

    input.price-presale {
      width: 100% !important;
    }

    label.after-heading {
      padding-top: 13px !important;
      font-size: 12px !important;
    }

    input.pricing-time {
      margin-left: 0 !important;
      width: 100% !important;
      margin-top: 12px;
    }

    input.after-price-month {
      width: 100% !important;
    }
  }

  .form-group {
    font-size: 12px;
  }

  .tickets-bookings .form-group label:before {
    padding: 8px !important;
  }

  .tickets-bookings .form-group input:checked + label:after {
    top: 6px;
    left: 5.4px;
    border-width: 5px 5px 5px 5px;
  }

  .startPresale {
    display: block !important;

    label.pre-sale-title {
      font-size: 12px !important;
    }
  }

  input.presale-month {
    width: 100% !important;
  }

  input.presale-time {
    margin-left: 0 !important;
    width: 100%;
    margin-top: 12px;
  }

  input.Endsale-month {
    width: 100%;
  }

  input.Endsale-time {
    margin-left: 0 !important;
    width: 100%;
    margin-top: 12px;
  }

  .sponsess-inputs {
    display: block !important;

    label.pre-spnser {
      font-size: 12px !important;
    }

    input.prespon-search {
      width: 100% !important;
    }

    input.prespon-promo {
      width: 100% !important;
    }
  }

  .EventChildHeader {
    .headding-child-Headers {
      display: block;

      .draft-public {
        margin-left: 0;
        margin: 5px 9px;
        margin-right: 15px;

        .selected-items {
          width: 100%;
          padding: 10px !important;
          border-radius: 0;
          height: 45px;
          color: #000;
        }
      }
    }
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  .events-Child {
    width: calc(100% - 0px);
    padding-left: 10px;
    padding-right: 10px;

    span.grnt {
      display: none;
    }

    div#nav-tab {
      flex-direction: column;
    }

    .event-Genral-info {
      padding: 20px 0px 0px 0px;

      .inputField {
        padding: 2px 0px 10px 0px;
      }
    }

    .Subtitle {
      padding-top: 10px !important;

      .textArea {
        margin-top: 2px !important;
      }
    }

    .booking-event {
      display: block !important;
      margin-top: 15px;

      .timesanddate {
        display: block !important;
        margin-top: 2px;

        input.months {
          margin-right: 0;
          width: 100%;
        }

        input.time {
          margin-top: 12px;
          width: 100%;
        }
      }

      .event-end {
        margin-top: 12px;
        margin-left: 0;

        .evntsEndtime {
          display: block !important;
          margin-top: 2px;

          input.time {
            margin-top: 12px;
            width: 100%;
          }

          input.months {
            margin-right: 0;
            width: 100%;
          }
        }
      }
    }

    .input-time {
      margin-top: 2px !important;
    }

    input.Door-time {
      width: 100%;
    }

    .seo-input {
      margin-top: 7px !important;
    }

    .venue-input {
      margin-top: 2px !important;
    }

    span.select-seating {
      font-size: 17px !important;
    }

    .book {
      display: block !important;

      span.onsale {
        font-size: 14px !important;
        padding: 4px !important;
      }

      .onsalestart {
        display: block !important;

        input.choose-month {
          margin-right: 0;
          width: 100%;
        }

        input.choose-time {
          width: 100%;
          margin-top: 12px;
        }
      }

      .cut-off {
        margin-top: 12px;
        margin-left: 0;

        span.head-cutoff {
          font-size: 14px !important;
          padding: 4px !important;
        }

        .cutoffstarts {
          display: block !important;
          margin: 0;

          input.choose-months {
            margin-right: 0;
            width: 100%;
          }

          input.choose-time {
            margin-top: 12px;
            width: 100%;
          }
        }
      }
    }

    .tickets-bookings {
      padding: 25px 0px 0px 0px;
    }

    label.genralTic {
      font-size: 14px !important;
      padding: 4px !important;
    }

    button.addd-button {
      width: 100%;
    }

    .hrline {
      display: none !important;
    }

    label.nickname {
      font-size: 14px;
      padding: 4px !important;
    }

    label.nicklinks {
      font-size: 14px;
      padding: 4px !important;
    }

    label.price-tag {
      font-size: 14px;
      padding: 4px !important;
    }

    input.enter-price {
      width: 100%;
    }

    span.seat-name {
      font-size: 16px !important;
      padding: 14px !important;
    }

    h3.accepts {
      font-size: 16px !important;
    }

    span.cards {
      font-size: 14px;
    }

    span.Mastercard {
      font-size: 14px;
    }

    span.AmericanExpress {
      font-size: 14px;
    }

    span.Discover {
      font-size: 14px;
    }

    span.cash {
      font-size: 14px;
    }

    .addPakages-btn {
      padding: 34px 0px 0px 0px;

      button.addpack {
        width: 100%;
      }

      .hrlines {
        display: none !important;
      }
    }

    .media {
      padding-top: 30px;

      button.addpack {
        width: 100%;
      }

      .media-content {
        padding: 10px !important;
      }

      .typing-area {
        padding: 10px !important;
      }

      span.iconic {
        font-weight: 600;
      }

      label.titles-contents {
        font-size: 14px;
      }

      span.delete-medias-section {
        display: none;
      }
    }

    .selection-items {
      padding: 34px 0px 0px 0px;
    }
  }

  span.delete-team-member {
    display: none;
  }

  .change-price {
    display: block !important;

    input.price-presale {
      width: 100% !important;
    }

    input.after-price-month {
      width: 100% !important;
    }

    input.pricing-time {
      margin-left: 0 !important;
      width: 100% !important;
      margin-top: 12px;
    }
  }

  .startPresale {
    display: block !important;

    input.presale-month {
      width: 100%;
    }

    input.presale-time {
      margin-left: 0 !important;
      width: 100%;
      margin-top: 12px;
    }

    input.Endsale-month {
      width: 100%;
    }

    input.Endsale-time {
      margin-left: 0 !important;
      width: 100%;
      margin-top: 12px;
    }
  }

  .sponsess-inputs {
    display: block !important;
  }

  input.prespon-search {
    width: 100% !important;
  }

  input.prespon-promo {
    width: 100% !important;
  }
}

.deleteTemplateButton {
  border: none;
  background-color: transparent;
  font-size: 14px;
  font-weight: 600;
  color: #ff2851;

  span.deleteText {
    margin: 0 6px;
  }
}

@media (min-width: 768px) and (max-width: 979px) {
  button.addpack {
    width: 200px;
  }

  .addd-button {
    width: 200px;
  }

  .events-Child {
    // padding-top: 153px;
    width: calc(100% - 0px);
    padding-left: 10px;
    padding-right: 10px;

    .event-Genral-info {
      padding: 23px 0px 0px 0px;

      .booking-event {
        display: block !important;

        .timesanddate {
          margin-top: 2px !important;
        }
      }

      .inputField {
        padding: 2px 0px 0px 0px;
      }

      .Subtitle {
        padding-top: 15px !important;

        .textArea {
          margin-top: 2px;
        }
      }
    }

    .book {
      display: block !important;

      span.onsale {
        font-size: 15px !important;
        padding: 4px !important;
      }

      .cut-off {
        margin-top: 12px;
        margin-left: 0;

        span.head-cutoff {
          font-size: 15px !important;
          padding: 4px !important;
        }

        .cutoffstarts {
          margin-top: 2px !important;
        }
      }
    }

    .add-tickets-btn {
      display: flex;
      align-items: center;

      .hrline {
        width: 68% !important;
        height: 2px;
      }
    }

    input.enter-price {
      width: 20%;
    }

    .pack-buttons {
      display: flex;
      align-items: center;
    }

    .hrlines {
      width: 65% !important;
    }

    .addPakages-btn {
      padding: 34px 0px 0px 0px;
    }
  }

  span.delete-team-member {
    display: none;
  }

  .change-price {
    display: block !important;

    input.price-presale {
      width: 100% !important;
    }

    input.after-price-month {
      width: 100% !important;
    }

    input.pricing-time {
      margin: 0 !important;
      width: 100% !important;
      margin-top: 12px !important;
    }
  }

  .startPresale {
    display: block !important;

    input.presale-month {
      width: 100%;
    }

    input.presale-time {
      margin-left: 0 !important;
      width: 100%;
      margin-top: 12px;
    }

    input.Endsale-month {
      width: 100%;
    }

    input.Endsale-time {
      margin-left: 0 !important;
      width: 100% !important;
      margin-top: 12px;
    }
  }

  .sponsess-inputs {
    display: block !important;

    input.prespon-search {
      width: 100% !important;
    }

    input.prespon-promo {
      width: 100% !important;
    }
  }
}

.doorTimeField {
  width: 100%;
  padding: 8px;
}

@media (min-width: 979px) and (max-width: 1280px) {
  .events-Child {
    // padding-top: 153px;
    width: calc(100% - 0px);
    padding-left: 10px;
    padding-right: 10px;

    .event-Genral-info {
      padding: 34px 0px 0px 0px;
    }

    .tickets-bookings {
      padding: 34px 0px 0px 0px;

      .cutoffstarts {
        margin-top: 0 !important;
      }
    }

    .addPakages-btn {
      padding: 34px 0px 0px 0px;
    }

    .hrlines {
      width: 75%;
    }

    .pack-buttons {
      display: flex;
      align-items: center;
    }
  }

  button.accordion-button.collapsed {
    // width: 76% !important;
    z-index: 0;
  }

  button.accordion-button {
    // width: 76% !important;
    z-index: 0 !important;
  }

  input.after-price-month {
    width: 48% !important;
  }

  input.pricing-time {
    width: 48% !important;
    margin-left: 11px !important;
  }

  input.presale-month {
    width: 47%;
  }

  input.presale-time {
    width: 48%;
    margin-left: 9px !important;
  }

  input.Endsale-month {
    width: 48%;
  }

  input.Endsale-time {
    width: 48%;
  }

  .EndPresale-content {
    margin-left: 20px;
  }

  input.prespon-search {
    width: 262px !important;
  }

  input.prespon-promo {
    width: 262px !important;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  div#nav-tab .nav-link.active {
    margin-top: 0 !important;
    padding-top: 8px !important;
    border-left: 1px solid grey !important;
    border-bottom: 1px solid grey !important;
  }

  div#nav-tab .nav-link {
    border-bottom: 1px solid grey !important;
    border-left: 1px solid grey !important;
  }
}

.errorMsgs {
  color: red;
  margin-left: 11px;
}

.EventChildHeader {
  nav.navbar-child {
    display: block;
  }
}

.CommonHeading {
  color: rgb(0, 0, 0);
  font-weight: bold;
  font-size: 14px;
  font-family: "Roboto Condensed";
  padding: 0;
  margin: 15px 0;
}

.EventPackageSec {
  margin: 15px 0;
  padding: 15px 20px;
  background-color: #fff;

  button.delete-btn-tickets {
    background-color: #fff;
    border: 0;

    a.Delete-tickets {
      text-decoration: none;
      color: #ff2851;
      font-family: Lato;
      font-size: 13px;
      font-weight: bold;

      img {
        position: relative;
        left: -4px;
        top: -2px;
      }
    }
  }

  input {
    width: 100%;
    padding: 8px;
  }

  .btn-box {
    margin-top: 25px;
    text-align: end;

    button.delete-btn {
      border: none;
      width: max-content;
      background-color: transparent;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;

      img {
        margin: 0 8px;
        position: relative;
        top: -1px;
      }
    }
  }
}

@media (max-width: 768px) {
  span.as-a {
    display: none;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .pack-buttons {
    .addpack {
      width: 100%;
    }
  }
}
@media (max-width: 992px) {
  .tickets-bookings .change-price .changedPrice {
    margin: 0;
  }
}
@media (min-width: 768px) {
  .pack-buttons {
    display: flex;
    align-items: center;

    button.addpack {
      width: 200px;

      .hrline {
        border-top: 2px solid lightgray;
        position: relative;
        top: 0px;
        width: 100%;
        float: right;
        margin-left: 20px;
      }
    }
  }
}

.pack-buttons {
  .hrline {
    border-top: 2px solid lightgray;
    position: relative;
    top: 0px;
    width: 100%;
    float: right;
    margin-left: 20px;
  }
}

.EventImagesSmall {
  margin-top: 25px;

  img {
    width: 90px;
    height: 90px;
    object-fit: cover;
    margin: 10px;
  }
}

.Delete-tickets-package {
  text-decoration: none;
  color: #ff2851;
  font-family: Lato;
  font-size: 13px;
  font-weight: bold;

  img {
    position: relative;
    left: -4px;
    top: -2px;
  }
}

.save_delete_button {
  display: flex;
  justify-content: space-between;
}

.delete_button {
  border: none;
  background-color: transparent;
  font-size: 14px;
  font-weight: 600;
  color: #ff2851;

  span {
    margin: 0 6px;
  }
}
