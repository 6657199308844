input.sponser-sketck {
  width: 100%;
  border: 1px solid lightgray;
  padding: 8px 10px;
  border-radius: 0;
}
.questionRow {
  margin: 15px 0;
  .QuestionInput {
    display: flex;
  }
  button {
    display: flex;
    align-items: center;
    margin: 0 10px;
    border: 0;
    color: #ff2851;
    background-color: transparent;
    .deleteIcon {
      margin: 0 5px;
      line-height: 0;
    }
  }
}
input.sponser-sketck:focus {
  border-color: lightgray;
  box-shadow: none;
}
.QuestionAnswerList {
  margin: 25px 10px;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  padding: 20px 10px;
  border-radius: 5px;
  .QuestionList {
    margin: 5px;
    margin-bottom: 10px;
    font-weight: 600;
  }
  .AnswerList {
    margin: 5px;
  }
}
@media (max-width: 480px) {
  .jobsChild {
    width: 100% !important;
    top: 190px;
  }
}
