.footers {
  position: fixed;
  bottom: 0;
  right: 0;
  width: calc(100% - 19.6%);
  z-index: 4;

  span.deletepakagestosave {
    margin-left: 6px;
    color: #FF2851;
    font-family: Lato;
    font-size: 13px;
    font-weight: bold;
    margin: 0px 10px;
    position: relative;
    top: 2px;
  }

  .footer-box {
    border: 1px solid lightgray;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 25px 20px 10px;

    button.footer-delete-btn {
      background-color: white;
      margin-left: 19px;
      border: none;

      a.Delete-footer {
        text-decoration: none;
        color: black;

        span.deletepakagestosave {
          margin-left: 6px;
        }
      }
    }

    .back-save-btns {
      display: flex;

      .back-btn {
        margin-right: 16px;

        button.back-button {
          background-color: black;
          color: white;
          border: none;
          padding: 10px 30px 10px 30px;
        }
      }

      button.save-button {
        background-color: #ff2851;
        color: white;
        border: none;
        padding: 10px 30px 10px 30px;
      }
    }
  }
}

.footer-box-second {
  border: 1px solid lightgray;
  background-color: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 25px 20px 10px;

  button.footer-delete-btn {
    background-color: white;
    margin-left: 19px;
    border: none;

    a.Delete-footer {
      text-decoration: none;
      color: black;

      span.deletepakagestosave {
        margin-left: 6px;
      }
    }
  }

  .back-save-btns {
    display: flex;

    .back-btn {
      margin-right: 16px;

      button.back-button {
        background-color: black;
        color: white;
        border: none;
        padding: 10px 30px 10px 30px;
      }
    }

    button.save-button {
      background-color: #ff2851;
      color: white;
      border: none;
      padding: 10px 30px 10px 30px;
    }
  }
}

@media (min-width: 280px) and (max-width: 480px) {
  .footers {
    padding-left: 0;
    width: inherit;
    left: 0;
  }

  .delte-btn {
    display: none;
  }

  .footer-box {
    justify-content: center !important;
  }
}

@media (min-width: 480px) and (max-width: 735px) {
  .footers {
    padding-left: 0;
    width: inherit;
    left: 0;
  }

  .delte-btn {
    display: none;
  }

  .footer-box {
    justify-content: center !important;
    padding: 15px 10px !important;
  }
}

@media (min-width: 735px) and (max-width: 979px) {
  .footers {
    padding-left: 0;
    width: 100%;
    left: 0;
  }
}

@media (min-width: 979px) and (max-width: 1280px) {
  .footers {
    padding-left: 0;
    width: 100%;
    left: 0;
  }
}

@media (min-width: 320px) and (max-width: 764px) {
  .footer-box-second {
    justify-content: center;
    padding: 10px 0;
  }
}

.disabledEventSubmit {
  opacity: 0.7;
  cursor: no-drop;
}