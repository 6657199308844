@font-face {
  font-family: "Oswald-Bold";
  src: url("./Assets/Fonts/Oswald-Bold.ttf");
  src: url("./Assets/Fonts/Oswald-SemiBold.ttf");
}

@font-face {
  font-family: "Oswald-Regular";
  src: url("./Assets/Fonts/Oswald-Regular.ttf");
}

@font-face {
  font-family: "Oswald-Medium";
  src: url("./Assets/Fonts/Oswald-Medium.ttf");
}

@font-face {
  font-family: "Oswald-Light";
  src: url("./Assets/Fonts/Oswald-Light.ttf");
}

@font-face {
  font-family: "Kanit-Light";
  src: url("./Assets/Fonts/Kanit-Light.ttf");
}

@font-face {
  font-family: "Kanit-SemiBold";
  src: url("./Assets/Fonts/Kanit-SemiBold.ttf");
}

body {
  background-color: #f6f6f6;
}

::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

.wrapper .sidebar ul li a {
  display: block;
  letter-spacing: 0em;
  padding: 18px 0;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  text-align: left;
  color: #ffffff;
  line-height: 25px;
  border-top: 1px solid black;
  width: 98%;
}

ul.sideListItem {
  padding-left: 0rem !important;
}

img.iconimg {
  margin-left: 72px;
}

span.item {
  margin-left: 11px;
  font-size: 15px;
}

/* .wrapper .sidebar ul li a.active, */
.wrapper .sidebar ul li a:hover {
  color: #fff;
  background: #ff2851;
}

.logo-waterfront {
  text-align: center;
  height: 79px;
  padding: 15px 10px;
}

img.logo-logo {
  width: 124px;
  height: 48px;
}

/* ------------------------------------------------------------- */

/* header.style */

.wrapper {
  display: flex;
  align-items: flex-start;
}

.header {
  width: 100%;
}

nav.navbar {
  width: 100%;
  padding: 10px 21px 4px 15px;
  background-color: #fefefe;
  position: fixed;
  z-index: 2;
}

a.navbarBrand {
  text-decoration: none;
  color: black;
  font-size: 32px;
  font-family: "Oswald-Light";
  font-weight: bold;
}

.content-Event {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.headding {
  display: flex;
  align-items: center;
}

.EventForm {
  width: 100% !important;
}

.Addnew {
  padding: 5px 50px 4px 50px;
  background-color: #ff2851;
  color: white;
  border-radius: unset;
  border: none;
  width: max-content;
}

span.sort {
  margin-right: 4px;
  margin-left: 15px;
  font-weight: 600;
}

span.by {
  font-weight: 600;
}

.searchBar {
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
}

.sectionBar {
  display: flex;
  width: 100%;
}

.selector {
  border: none;
  color: red;
  background-color: #fefefe;
}

option {
  background-color: gray;
  color: white;
}

.TopCommon {
  position: relative;
  top: 100px;
  width: 95% !important;
  margin: auto;
}

.TopCommonChild {
  position: relative;
  top: 80px;
  padding: 0 20px;
  width: 95%;
  margin: auto;
}

.searchicone {
  text-decoration: none;
  color: #c4c4c4;
}

.backimage {
  margin-right: 10px;
}

.form-check-input:focus {
  box-shadow: none;
  border-radius: 0;
}
.homeBannerSearchSec {
  width: 31% !important;
}
.homeBannerSearchSec input.search {
  margin-right: 0;
}

.form-check-input[type="checkbox"] {
  border-radius: 0;
  border: 3px solid white !important;
  outline: 1px solid rgb(0, 0, 0);
  background-image: none !important;
  padding: 5px;
}

.form-check-input[type="checkbox"]:focus {
  border-radius: 0.25em;
  border-radius: 0;
  padding: 5px;
  box-shadow: none;
}

.form-check .form-check-input {
  margin-left: 0;
}

input {
  cursor: pointer;
}

.form-check-input[type="radio"] {
  border-radius: 0;
  border: 3px solid white !important;
  outline: 1px solid rgb(0, 0, 0);
  background-image: none !important;
  padding: 6px;
  margin: 0;
}

.form-check-input:checked {
  border-radius: 0;
  border: 3px solid white;
  outline: 2px solid rgb(0, 0, 0);
  background-color: #4cb8dc !important;
  background-image: none !important;
}

.CommonLabel {
  font-size: 14px;
  padding: 10px;
  color: #000;
  font-weight: bold;
  /* font-family: 'Roboto Condensed'; */
  font-family: "Roboto", sans-serif;

  display: block;
}

input {
  border: solid 1px lightgray;
  outline: 1px black;
}

.HeaderInline {
  display: flex;
  align-items: baseline;
}

.HeaderWithDate {
  margin: 0 10px;
}

a.navbarBrand-childeheaders {
  text-decoration: none;
  color: black;
  font-size: 28px;
  font-weight: bold;
  font-family: "Roboto Condensed", sans-serif;
}

p.lastEditeby {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 10px;
  line-height: 0px;
  margin: 2px 0;
}

.jodit-toolbar__box:not(:empty):not(:empty) {
  border-radius: 0;
}

.jodit-status-bar {
  display: none !important;
}

.MainClass {
  padding-bottom: 16rem;
}

.HeaderForm {
  width: 50%;
}

input.search {
  width: 100%;
}

.AddCityHeader {
  display: flex;
  align-items: center;
}

.AddCityNav {
  margin: 0 10px;
}

.ql-align-right {
  text-align: right;
}

.ql-align-center {
  text-align: center;
}

.ql-align-justify {
  text-align: justify;
}

.ql-align-left {
  text-align: left;
}

.ql-editor {
  p {
    text-align: right;
  }
}

.css-9ma7ck-OptionComponent {
  background: #fff !important;
  color: #555 !important;
  border: 1px solid #aaa;
  white-space: nowrap;
  width: max-content;
  margin: 1px 2px !important;
}

.textEditer-sponsers {
  margin-bottom: 20px;
}
.selector:focus {
  outline: 0;
}

@media (min-width: 320px) and (max-width: 479px) {
  .TopCommonChild {
    top: 135px;
    width: 100%;
  }

  .MainClass {
    padding-bottom: 16rem;
  }

  p.lastEditeby {
    line-height: 12px;
  }

  a.searchicone {
    display: none;
  }
}

@media (min-width: 479px) and (max-width: 768px) {
  .TopCommonChild {
    top: 150px;
    width: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 768px) and (max-width: 992px) {
  .TopCommonChild {
    top: 150px;
  }

  .MainClass {
    padding-bottom: 18rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 992px) and (max-width: 1200px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media (min-width: 1200px) {
}

@media (max-width: 736px) {
  .TicketsHeader .sectionBar {
    display: none;
  }
  .eventsChilds-container {
    margin-bottom: 18rem;
  }
}

@media (min-width: 736px) {
  a.searchicone {
    position: absolute;
    left: 8px;
  }

  .dropdown {
    width: 50%;
  }

  .form {
    width: 50%;
  }

  .FaqHeader .form {
    width: unset;
  }

  .TicketsHeader .form {
    width: unset;
  }
}

@media (min-width: 736px) {
  .SearchFeildMobile {
    display: none;
  }
}

@media (min-width: 479px) and (max-width: 736px) {
  a.searchicone {
    display: none;
  }
}
