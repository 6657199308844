.teamChild {
  .errorMsgs {
    color: red;
    margin-left: 11px;
  }
  input.FirstName {
    width: 100%;
    padding: 8px 10px 8px 10px;
    border: 1px solid lightgray;
  }
  .Lname {
    padding-top: 17px;

    input.lastName {
      width: 100%;
      padding: 8px 10px 8px 10px;
      border: 1px solid lightgray;
    }
  }
  .emails {
    padding-top: 17px;

    input.Admin-Email-addd {
      width: 100%;
      padding: 8px 10px 8px 10px;
      border: 1px solid lightgray;
    }
  }
}
@media (min-width: 280px) and (max-width: 480px) {
 
}
@media (min-width: 480px) and (max-width: 735px) {

}
@media (min-width: 735px) and (max-width: 979px) {

}
@media (min-width: 979px) and (max-width: 1280px) {
 
}
