.Child-Venues {
  .CommonLabel {
    font-size: 14px;
    padding: 10px;
    color: #000;
    font-weight: bold;
    font-family: "Roboto Condensed";
    margin-top: 14px;
    display: block;
  }

  select.citiesnames:focus-visible {
    outline: 0;
  }

  label.venuse-titles {
    padding: 9px;
    font-size: 15px;
    font-weight: 600;
  }

  input.aura {
    width: 100%;
    padding: 8px 15px 8px 17px;
    border: 1px solid lightgray;
  }

  .box-offices {
    display: flex;
    justify-content: space-between;
    margin-top: 7px;

    .boxOfficeHeading {
      width: 100%;
      margin-right: 10px;
    }

    .office-working-hours {
      width: 100%;
    }

    input.timestophone {
      width: 100%;
      padding: 8px 10px;
    }

    input.timestoHours {
      width: 100%;
      padding: 8px 10px;
    }
  }

  .VenueFields {
    .cities {
      width: 100%;

      select.citiesnames {
        width: 100%;
      }
    }

    .zips {
      width: 100%;
    }
  }

  .detailsandAddress {
    display: flex;
    justify-content: space-between;

    label.select-cities {
      font-weight: 600;
      font-size: 15px;
      padding: 6px;
    }

    select.citiesnames {
      padding: 10px 15px 8px 17px;
      border: 1px solid lightgray;
      background-color: #fff;
    }

    .address {
      margin-left: 17px;

      label.Addreclient {
        padding: 6px;
        font-size: 15px;
        font-weight: 600;
      }

      input.realAddress {
        padding: 8px 15px 8px 17px;
        width: 300px;
        border: 1px solid lightgray;
      }
    }

    .zipcode {
      margin-left: 19px;

      input.zipnumber {
        width: 100%;
        padding: 8px 15px 8px 17px;
        border: 1px solid lightgray;
      }
    }
  }

  .seo {
    padding-top: 20px;

    input.aurostreet {
      width: 100%;
      padding: 8px 15px 8px 17px;
      border: 1px solid lightgray;
    }

    input.centerstreet {
      margin-top: 15px;
      width: 100%;
      border: 1px solid lightgray;
      padding: 8px 15px 8px 17px;
    }
  }
}

.rightbar {
  padding-top: 15px;
  word-wrap: break-word;

  .seatingscharts-venues {
    padding: 10px 20px;
  }

  button.uploadbtn {
    width: 100%;
    padding: 10px 10px 8px 10px;
    border: none;
    background-color: #ff2851;
    color: white;
    font-size: 18px;
    font-weight: 600;
    border-radius: inherit;
  }

  button.uploadbtn:active {
    background-color: #ff2851;
  }

  h4.charts {
    font-size: 18px;
    margin: 15px 0;
    font-family: "Roboto Condensed";
    font-weight: 600;
  }

  .seatingsCharts-box {
    background-color: white;
    padding: 10px;
    margin-top: 15px;

    img.seating-chart-img {
      width: 100%;
    }
  }

  .payments {
    margin-top: 20px;

    .paymentstypebox {
      background-color: white;
      padding: 10px 20px;

      .typesofpayment {
        div {
          margin: 20px 0;

          span {
            margin: 0 10px;
          }
        }
      }
    }

    h4.type-of-payments-accepts {
      font-size: 20px;
      font-weight: 500;
    }
  }

  .form-check-input[type="radio"] {
    border-radius: 5%;
    width: 20px;
    height: 20px;
  }

  .form-check.form-check {
    margin-top: 16px;
  }
}

@media (min-width: 300px) and (max-width: 480px) {
  .Child-Venues {
    .box-offices {
      display: block;
    }

    .detailsandAddress {
      flex-wrap: wrap;
      display: block;
    }

    input.timestophone {
      width: 100% !important;
    }

    input.timestoHours {
      width: 100% !important;
    }

    .address {
      margin-left: 0 !important;

      input.realAddress {
        width: 100% !important;
      }
    }

    .zipcode {
      margin-left: 0 !important;
    }

    select.citiesnames {
      width: 100%;
      // border-radius: 5px;
      height: 45px;
      color: #000;
    }
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  .Child-Venues {
    .box-offices {
      display: block;
    }

    .detailsandAddress {
      flex-wrap: wrap;
      display: block;
    }

    input.timestophone {
      width: 100% !important;
    }

    input.timestoHours {
      width: 100% !important;
    }

    .address {
      margin-left: 0 !important;

      input.realAddress {
        width: 100% !important;
      }
    }

    .zipcode {
      margin-left: 0 !important;
    }

    select.citiesnames {
      width: 100%;
    }
  }
}

@media (min-width: 768px) and (max-width: 979px) {
  .addresses {
    width: 100%;

    input.realAddress {
      width: 100% !important;
    }
  }

  input.timestophone {
    width: 467px !important;
  }

  input.timestoHours {
    width: 467px !important;
  }

  .detailsandAddress {
    flex-wrap: wrap;
  }

  .address {
    margin-left: 0 !important;
  }

  .zipcode {
    margin-left: 0 !important;
  }

  .box-offices {
    flex-wrap: wrap;
    display: block;
  }
}

@media (min-width: 979px) and (max-width: 1280px) {
  .Child-Venues {
    .box-offices {
      flex-wrap: wrap;
    }

    input.timestophone {
      width: 601px !important;
    }

    input.timestoHours {
      width: 601px !important;
    }

    // input.zipnumber {
    //   width: 89px !important;
    // }
  }
}

@media (min-width: 1280px) and (max-width: 1411px) {
  input.timestophone {
    width: 300px !important;
  }

  input.timestoHours {
    width: 300px !important;
  }
}

.enable-location-btn {
  background-color: #4cb8dc;
  padding: 11px;
  border: none;
  border-radius: 0.5rem;
  margin: 10px;
  color: white;
}
