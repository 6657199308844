.tickets-container {
  .accordion-collapse {
    background-color: #fff;
    border: 1px solid #dddddd;
  }

  h2.HeadingPath.accordion-header {
    border: 1px solid #d7d7d7;
    background-color: #fff;
  }

  .accordion-button:not(.collapsed) {
    box-shadow: none;
  }

  button.accordion-button.collapsed {
    .downArrow {
      transform: rotate(360deg);
    }
  }

  img.downArrow {
    width: 10px;
    height: 10px;
    transform: rotate(180deg);
  }

  .accordion-button:not(.collapsed)::after {
    background-image: none;
    display: none;
  }

  button.accordion-button::after {
    display: none;
  }

  button.accordion-button {
    padding: 0 10px;

    div#headingTwo {
      width: 100%;
      display: flex;
      align-items: baseline;
      margin: 0;
      padding: 10px 0;

      button.delete-btn {
        margin: 0 10;

        a.Delete-packages {
          display: flex;
          align-items: center;
          width: max-content;

          h6 {
            margin: 0 7px;
            font-family: Lato;
            font-size: 12px;
            font-weight: bold;
          }
        }
      }
    }
  }
}

.deletebg {
  background-color: #fff !important;

  .DeletedTickets {
    font-size: 13px;
    font-weight: 600;
    margin-left: 4px;
    font-family: lato;
    color: #ff2851;
    text-decoration: none;

    img {
      position: relative;
      top: -2px;
      left: -5px;
    }
  }
}

.DeletedTickets:hover {
  color: #ff2851;
}

.accordion-item {
  margin-bottom: 13px;
}

.tickets {
  padding: 10px;
  padding-left: 15px;
}

.Accordian-Faq-field.accordion-item {
  padding-bottom: 0;
}

.accordion-item {
  border-radius: inherit !important;
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;

  span.title-hed {
    color: #000000;
    font-family: Lato;
    font-size: 13px;
    font-weight: 900;
  }
}

button.Update-btn {
  padding: 4px 50px 5px 50px;
  border: none;
  background-color: #ff2851;
  color: white;
  font-weight: 600;
  font-size: 18px;
}

input.fields-text {
  width: 100%;
  margin-top: 15px;
  height: 45px;
  padding: 0px 10px 0px 10px;
  border: 1px solid lightgray;
  font-size: 13px;
}

button.accordion-button {
  border: 0px;
  box-shadow: unset;
  background-color: transparent;
}

.accordion-header {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  padding: 8px;
  .PresalessContent {
    background-color: #f6f6f6 !important;
  }

  .accordion-button:not(.collapsed) {
    background-color: #fff;
    justify-content: space-between;
    align-items: center;
  }

  button.accordion-button:focus {
    box-shadow: none;
    // background-color: #f6f6f6;
  }
}

.acording-dropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px 8px 17px;
  border-bottom: 1px solid lightgray;

  button.accordion-button.tickets-contents {
    background-color: white !important;
    font-family: Lato;
    font-size: 13px;
    padding: 9px !important;
  }
}

button.delete-btn {
  border: none;
  width: max-content;
  background-color: #f6f6f6;
  // display: flex;
}

a.Delete-packages {
  text-decoration: none;
  color: #ff2851;
  font-family: Lato;
  font-size: 13px;
  font-weight: bold;
}

button.accordion-button.collapsed {
  font-weight: 600;
}

button.accordion-button {
  color: black !important;
  font-weight: 600;
}

.accordion-item.ticketscontent {
  border: 1px solid lightgray;
  margin-bottom: 12px;
  padding: 5px;
  background-color: white;
  padding-bottom: 0px;
}

.tickets-contents {
  background-color: white !important;
}

.tickets-popup-title {
  display: flex;
  .popup-content-area {
    .content-type {
      margin-top: 30px;
    }
  }

  input.popup-input-fields {
    width: 100%;
    margin-left: 40px;
  }
}

button.cancel-btn.btn.btn-primary {
  background-color: white;
  color: black;
  border: 1px solid lightgray;
  font-weight: 600;
}

button.create-btn.btn.btn-primary {
  background-color: #428bca;
  font-weight: 600;
}

button.accordion-button.collapsed.tickets-contents {
  background-color: white !important;
}

button.newTickets.btn.btn-primary {
  background-color: #ff2851;
  border: none;
  border-radius: inherit;
  font-weight: 600;
}

.accordion-body {
  padding-top: 15px;

  .TextEditorSec {
    .ContentHeading {
      margin-top: 15px;
      font-size: 13px;
      font-weight: 700;
    }
  }
}

@media (min-width: 300px) and (max-width: 480px) {
  .tickets-container {
    width: calc(100% - 0px);
  }

  button.Update-btn {
    padding: 3px 7px 3px 8px;
  }

  h6.delete-team-member {
    display: none;
  }
}

@media (min-width: 480px) and (max-width: 735px) {
  button.Update-btn {
    padding: 5px 15px 5px 15px;
  }
}

@media (min-width: 735px) and (max-width: 979px) {
  button.Update-btn {
    padding: 7px 20px 7px 20px;
  }
}

@media (min-width: 979px) and (max-width: 1280px) {
  button.Update-btn {
    padding: 5px 25px 5px 25px;
  }
}
