.Team-container {
  top: 100px;
  width: 100%;
  padding: 0 20px;
}

.Team {
  margin: 0;
  width: 95%;
  margin: auto;
  position: relative;
  top: 100px;

  .Team-Box {
    border: 1px solid lightgray;
    background-color: white;
    margin-bottom: 20px;
    padding: 25px 30px 10px 0px;
  }

  ul.content-item {
    list-style: none;
    cursor: pointer;
  }

  li.team-pakages-pakages {
    display: flex;
    justify-content: space-between;
  }

  a.Team-include {
    text-decoration: none;
    color: black;
  }

  span.Team-Name {
    font-size: 22px;
    font-weight: 600;
    font-family: "Roboto Condensed";
    text-transform: uppercase;
  }

  span.email {
    font-size: 13px;
  }

  .Packages-contents {
    line-height: 18px;
  }

  button.delete-btn {
    border: none;
    background-color: white;
    color: #ff2851;
    font-size: 12px;
    font-weight: bold;

    img.fa-solid.fa-trash-can {
      margin-top: -4px;
      margin-right: 6px;
    }

    a.Delete-team {
      text-decoration: none;
      color: red;

      i.fa-solid.fa-trash-can {
        margin-right: 6px;
        font-size: 13px;
      }

      span.delete-team-member {
        font-size: 13px;
        font-weight: 700;
        margin-left: 6px;
      }
    }
  }
}

@media (min-width: 300px) and (max-width: 480px) {
  .Team-container {
    top: 105px;
    width: calc(100% - 0px);
  }

  .Team {
    margin: 35px 20px 12px 19px;

    .Team-Box {
      padding: 25px 0px 10px 0px;
    }

    ul.content-item {
      padding-left: 9px;
    }

    span.delete-team-member {
      display: none;
    }

    button.delete-btn {
      margin-right: 8px !important;
    }

    .heading-content {
      margin-left: 16px !important;
    }
  }
  .pagination > li {
    margin-left: 5px !important;
  }
  .pagination > li > a,
  .pagination > li > span {
    float: unset !important;
    padding: 5px 10px !important;
  }
}

@media (min-width: 480px) and (max-width: 735px) {
  .Team-container {
    top: 115px;
    width: calc(100% - 0px);
  }

  .Team {
    margin: 35px 20px 12px 19px;
    top: 120px;

    .Team-Box {
      padding: 25px 0px 10px 0px;
    }

    ul.content-item {
      padding-left: 9px;
    }

    span.delete-team-member {
      display: none;
    }

    button.delete-btn {
      margin-right: 8px !important;
    }

    .heading-content {
      margin-left: 16px !important;
    }
  }
}

@media (min-width: 736px) and (max-width: 980px) {
  .Team {
    top: 150px;
  }
}

@media (min-width: 735px) and (max-width: 1280px) {
  .Team-container {
    top: 150px;
    width: calc(100% - 0px);
    padding: 0 10px;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}

.pagination > li {
  display: inline;
  margin-left: 9px;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 9px 16px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #428bca;
  background-color: #fff;
  border: 1px solid #acb5b7;
  margin-left: -1px;
  width: 44px;
  height: 44px;
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  z-index: 3;
  color: #fff;
  background-color: #4cb2dc;
  border-color: #4cb2dc;
  cursor: default;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.pagination > li > a:hover {
  background: #ddd;
  color: #2a6496;
}
