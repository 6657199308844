.icon_border {
  padding: 3px 9px 10px;
  border: 2px solid #fff;
  margin: 0 12px;
  cursor: pointer;
}
.icon_border_active {
  border-color: #d7d7d7;
}

.priceSection {
  display: flex;
  justify-content: space-between;
  input {
    width: 100% !important;
  }
}
.Notify-message {
  p {
    padding: 0px 15px 6px;
  }
}
.deleteTicket {
  border: none;
  display: flex;
  margin: 4px;
  padding: 4px;
  color: #ff2851;
  font-family: Lato;
  font-size: 13px;
  font-weight: bold;
  width: max-content;
}
.deleteImg {
  margin-right: 6px;
}

.presaleEditSec {
  .bookings-box {
    padding: 20px;
    .startPresale {
      padding: 0;
    }
    .sponser-presale-tickets {
      padding-top: 20px;
    }

    .btn-box {
      margin-top: 25px;
      text-align: end;
    }
  }
}

.ticketPriceNote .priceNote {
  font-size: 14px;
}
