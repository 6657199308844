.wrapper {
  display: flex;
  align-items: flex-start;
  position: relative;
}

.wrapper .scrolbar {
  background-image: url(../../Assets/SideBar-Image/sidebar-bg.png);
  background-repeat: no-repeat;
  width: calc(100% - 80%);
  height: 100%;
  background-size: cover;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  left: 0;
  top: -2px;
  margin-top: -4px;

  .wrapper .scrolbar ul li a {
    display: block;
    letter-spacing: 0em;
    padding: 1.35em 0;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    text-align: left;
    color: #ffffff;
    line-height: 30px;
    border-top: 1px solid black;
    width: 256px;

    ul.sideListItem {
      padding-left: 0rem !important;
    }

    img.iconimg {
      margin-left: 75px;
    }

    span.item {
      margin-left: 11px;
    }

    .scrolbar ul li a:hover {
      color: #fff;

      background: #ff2851;
      border-right: 2px solid white;
    }

    .logo-waterfront {
      text-align: center;
      height: 79px;
      padding: 15px 10px;
    }

    img.logo-logo {
      width: 129px;
      height: 48px;
    }
  }

  .wrapper {
    display: flex;
    align-items: flex-start;
  }

  nav.navbar {
    width: 100%;
    padding: 10px 48px 4px 15px;
    background-color: #fefefe;
  }

  a.navbarBrand {
    text-decoration: none;
    color: black;
    font-size: 32px;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: bold;
  }

  input.search {
    padding: 5px 11px 5px 10px;
    border: none;
    border-radius: unset;
    margin-right: 39px;
  }

  .Addnew {
    padding: 5px 50px 8px 50px;
    background-color: #ff2851;
    color: white;
    border-radius: unset;
    border: none;
  }

  i.fa-regular.fa-plus {
    display: none;
  }

  span.sort {
    margin-right: 4px;
    margin-left: 15px;
    font-weight: 600;
  }

  span.by {
    margin-left: -8px;
    font-weight: 600;
  }

  .dropdown.d-flex {
    margin-right: 165px;
  }

  .selector {
    border: none;
    color: red;
    background-color: #fefefe;
  }

  option {
    background-color: gray;
    color: white;
  }
}

.myLinks {
  .active {
    color: white;
  }
}

span.item {
  margin-left: 11px;
  font-size: 14px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
}

button.plusicon {
  display: none;
}

.header {
  width: calc(100% - 19.6%);
  position: absolute;
  right: 0;
}

/* ------------------------------------------------------------- */

/* header mobile view .style  */
.mobile-container {
  max-width: 480px;
  margin: auto;
}

.topnav {
  overflow: hidden;
  background-color: black;
  position: relative;
}

.topnav #myLinks {
  display: none;
}

.topnav a {
  color: white;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  display: block;
}

.topnav a.icon {
  background: black;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}

.topnav a.active {
  background-color: black !important;
}

.topnav a:hover {
  color: white;
}

.sidebar::-webkit-scrollbar {
  width: 4px;
}

.sidebar::-webkit-scrollbar-track {
  border-radius: 3px;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 10px;
}

a.active {
  background-color: #ff2851 !important;
}

@media (min-width: 280px) and (max-width: 480px) {
  .mobile-container {
    max-height: 76px;
  }

  .container {
    flex-wrap: nowrap !important;
    max-width: 100%;
  }

  .topnav {
    z-index: 10;
  }

  button.Addnew {
    display: none;
  }

  button.plusicon {
    background-color: #ff2851;
    color: white;
    border: none;
  }

  span.sort {
    display: none;
  }

  img.logo-logo {
    height: 35px;
    width: 94px;
  }

  a.navbarBrand {
    font-size: 32px;
    margin-right: 12px;
  }

  nav.navbar {
    padding: 11px;
  }

  .form {
    width: auto;
  }

  input.search {
    display: none;
  }

  i.fa.fa-bars {
    font-size: 34px;
  }

  i.fa-solid.fa-magnifying-glass {
    display: block;
  }

  button.plusicon {
    display: block;
  }

  a.active {
    padding: 5px 13px;
    border-bottom: 1px solid #302b2b;
  }
}

@media (min-width: 480px) and (max-width: 735px) {
  .mobile-container {
    max-height: 76px;
    max-width: 100%;
  }

  .topnav {
    z-index: 10;
  }

  i.fa.fa-bars {
    font-size: 40px;
  }

  input.search {
    display: none;
  }

  button.Addnew {
    display: none;
  }

  i.fa-solid.fa-magnifying-glass {
    display: block;
    font-size: 23px;
    color: gray;
  }

  button.plusicon {
    display: block;
    background-color: #ff2851;
    color: white;
    border: none;
    font-size: 17px;
    margin-left: 9px;
  }

  .container {
    flex-wrap: nowrap !important;
    max-width: 100%;
  }

  span.sort {
    display: none;
  }

  .searchBar {
    justify-content: flex-end;
    display: flex;
  }

  span.by {
    margin-left: 12px;
  }

  a.active {
    padding: 5px 13px;
    border-bottom: 1px solid #302b2b;
  }
}

@media (min-width: 735px) and (max-width: 979px) {
  .mobile-container {
    max-height: 76px;
    max-width: 100%;
  }

  .topnav {
    z-index: 10;
  }

  i.fa.fa-bars {
    font-size: 40px;
  }

  .container {
    flex-wrap: nowrap !important;
    max-width: 100%;
  }

  .searchBar {
    justify-content: flex-end;
    display: flex;
  }

  span.sort {
    margin-right: 4px;
    margin-left: 15px;
  }

  a.active {
    padding: 5px 13px;
    border-bottom: 1px solid #302b2b;
  }
}

@media (min-width: 979px) and (max-width: 1280px) {
  .mobile-container {
    max-height: 76px;
    max-width: 100%;
  }

  .topnav {
    z-index: 10;
  }

  i.fa.fa-bars {
    font-size: 40px;
  }

  .container {
    flex-wrap: nowrap !important;
    max-width: 100%;
  }

  .searchBar {
    justify-content: flex-end;
    display: flex;
  }

  span.sort {
    margin-right: 4px;
    margin-left: 15px;
  }

  a.active {
    padding: 14px 13px;
    border-bottom: 1px solid #1f262d;
  }
}

@media (min-width: 1280px) {
  .wrapper .scrolbar {
    height: 101vh;
  }
}

/* Extra small devices (phones, 600px and down) */
@media (min-width: 320px) and (max-width: 768px) {
  .MobileView {
    display: none !important;
  }

  .WebView {
    display: none;
  }

  .container-fluid {
    padding-left: 0;
  }

  .TopCommon {
    width: 100% !important;
    top: 150px;
    padding: 0 10px !important;

    .events-main {
      padding: 0 !important;
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 768px) and (max-width: 979px) {
  .TopCommon {
    top: 160px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 992px) and (max-width: 1200px) {
  .TopCommon {
    top: 100px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media (min-width: 1200px) {
}

@media (max-width: 1280px) {
  .MobileView {
    display: none;
  }

  .ColumbWidth {
    width: 100%;
  }
}

@media (min-width: 980px) and (max-width: 1280px) {
  .header {
    margin-top: 75px;
    position: relative;
  }
}

@media (max-width: 1280px) {
  div#myLinks {
    overflow: auto;
    height: 100vh;
  }
}

@media (min-width: 320px) and (max-width: 992px) {
  div#myLinks {
    overflow: auto;
    height: 100vh;
  }
}
@media (min-width: 320px) and (max-width: 380px) {
  .topnav a {
    padding: 7px 16px;
  }
  .topnav a.active {
    padding: 7px 16px;
  }
}
@media (min-width: 530px) and (max-width: 550px) {
  .topnav a {
    padding: 7px 16px;
  }
  .topnav a.active {
    padding: 7px 16px;
  }
}

@media (min-width: 1020px) and (max-width: 1030px) {
  .topnav a {
    padding: 7px 16px;
  }
  .topnav a.active {
    padding: 7px 16px;
  }
}
