nav.navbar {
  width: 100%;
  padding: 14px 55px 13px 0px;
  background-color: #fefefe;
  z-index: 4;
  border-bottom: 1px solid lightgray;
}
.HeaderArchive {
  button.ArchiveBtn {
    padding: 13px 30px;
    margin-right: 56px;
  }
}
button.FaqPopupbtn.btn.btn-primary {
  background-color: #ff2851;
  border: none;
  border-radius: inherit;
  font-weight: 600;
}

a.navbarBrand {
  text-decoration: none;
  color: black;
  font-size: 32px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: bold;
  margin-left: 15px;
}

input.search {
  padding: 5px 11px 5px 30px;
  border: none;
  border-radius: unset;
  margin-right: 30px;
  width: 100%;
  background-color: #f6f6f6;
}

.Addnew {
  background-color: #ff2851;
  color: white;
  border-radius: unset;
  border: none;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
}

button.Add-new-Packages {
  padding: 8px 12px;
  background-color: #ff2851;
  color: white;
  border-radius: unset;
  border: none;
  font-weight: 700;
  width: max-content;
}

i.fa-regular.fa-plus {
  display: none;
}

span.sort {
  margin-right: 4px;
  margin-left: 15px;
  font-weight: 600;
}

span.by {
  font-weight: 600;
}

.selector {
  border: none;
  color: #ff2851;
  background-color: #fefefe;
}
.selector:hover {
  cursor: pointer;
}

select.selector:focus-visible {
  outline: none !important;
  box-shadow: none;
}

option {
  background-color: #fff;
  color: black;
}

button.plusicon {
  display: none;
}

nav.navbar-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-child {
  width: 82%;
  padding: 10px 65px 12px 15px;
  background-color: #fefefe;
  position: fixed;
  z-index: 4;

  .headding-child-Headers {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .CitiesDateTimeRes {
    .lastEditeby {
      margin-left: 0;
    }
  }

  .CitiesHeight {
    line-height: 24px;
  }

  .child-Header-content {
    display: flex;
    align-items: center;

    a.navbarBrand-childeheaders {
      text-decoration: none;
      color: black;
      font-size: 28px;
      font-weight: bold;
      font-family: "Roboto Condensed";
      text-transform: uppercase;
    }
  }

  span.lastEditeby {
    font-size: 12px;
    margin-left: 27px;
  }

  select.selected-items {
    padding: 8px 30px 8px 22px;
  }
}

.featureVenueChildHeader {
  padding: 8px 3px;

  a.btnFeatureActive {
    margin: auto;
    background-color: #ff2851;
    padding: 10px;
    text-decoration: none;
  }

  a.childVenuheaderbuttons {
    text-decoration: none;
    border: 1px solid white;
    padding: 11px;
    color: white;
    font-weight: 600;
    background-color: lightgray;

    img {
      position: relative;
      top: -2px;
    }

    span.button-cnt {
      margin-left: 3px;
    }
  }
}

a.btnFeatureInactive {
  margin: auto;
  background-color: #dfdfdf;
  padding: 10px;
  text-decoration: none;
}

span.headers-Feature-button {
  outline: 1px solid;
  padding: 4px 10px;
  color: #fff;

  img {
    position: relative;
    top: -2px;
    left: -2px;
  }
}

.navbar-child-venues {
  width: 82%;
  padding: 10px 65px 10px 15px;
  background-color: #fefefe;
  position: fixed;
  z-index: 4;
}

nav.navbar-child-venues {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .headding-child-Headers {
    display: flex;
    align-items: center;
  }

  a.navbarBrand-childeheaders {
    font-size: 28px;
    text-decoration: none;
    color: black;
    font-weight: 600;
  }
}

button.addnewQuestion {
  padding: 8px 12px 8px 12px;
  border: none;
  background-color: red;
  color: white;
  font-weight: 600;
}

.FaqHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.HomeBannerChildSec {
  .headding-child-Headers {
    align-items: baseline;

    .child-Header-content {
      display: block;

      .dateResponse {
        position: relative;
        top: -10px;

        .lastEditeby {
          margin-left: 5px;
        }
      }
    }
  }
}

.ApplicentHeader {
  display: flex;
  align-items: center;
}

.VenueHeaderSec {
  width: 50%;
  position: relative;

  input.search {
    width: 62%;
    margin-right: 40px;
  }
}

.cityChildHeader {
  display: flex;
  justify-content: flex-start;

  .headding-child-Headers {
    align-items: baseline;

    .dateResponse {
      line-height: 1;
    }
  }
}

.TeamSubHeader {
  display: flex;
  align-items: center;
}

p.plusicon {
  display: block;
  background-color: #ff2851;
  color: white;
  border: none;
  font-size: 17px;
  margin-bottom: 0;
  padding: 4px 10px;
}

p.plusicon {
  display: none;
}

.bannerChildHeader {
  display: flex;
  align-items: center;
}

.TeamSearchinput {
  width: 45%;
}

.AddvenueSec {
  display: flex !important;
  justify-content: space-between;
}

@media (min-width: 280px) and (max-width: 480px) {
  button.FaqPopupbtn {
    display: none;
  }
  span.headers-Feature-button {
    padding: 4px 8px;
    img {
      position: relative;
      top: -3px;
      left: 0px;
    }
  }

  .TeamSearchinput {
    width: 30%;
  }

  select.selected-items {
    padding: 0 !important;
  }

  .container {
    flex-wrap: nowrap !important;
    max-width: 100%;
  }

  .header {
    width: 100%;
  }

  .topnav {
    z-index: 10;
    position: fixed;
    width: 100%;
  }

  button.Addnew {
    display: none;
  }

  button.plusicon {
    background-color: #ff2851;
    color: white;
    border: none;
  }

  span.sort {
    display: none;
  }

  .searchicone {
    margin: 0 10px;
  }

  .searchBar {
    margin-right: 0;
  }

  a.navbarBrand {
    font-size: 27px;
    margin: 0 5px;
  }

  .dropdown.d-flex {
    margin: 0;
  }

  nav.navbar {
    top: 63px;
    padding: 10px;
  }

  .form {
    width: auto;
  }

  input.search {
    display: none;
  }

  i.fa.fa-bars {
    font-size: 34px;
  }

  i.fa-solid.fa-magnifying-glass {
    display: block;
    font-size: 23px;
    color: gray;
  }

  button.plusicon {
    display: initial;
    padding: 4px 9px;
  }

  p.plusicon {
    display: block !important;
  }

  a.active {
    padding: 14px 16px;
  }

  i.fa-regular.fa-plus {
    display: initial;
  }

  nav.navbar-child {
    padding: 70px 15px 10px 15px;
    width: 100%;
    display: block;

    a.navbarBrand-childeheaders {
      font-size: 23px !important;
    }

    .dropdown-childHeader.d-flex {
      display: block !important;

      select.selector-childHeader {
        width: 100%;
      }
    }

    .draft-public {
      margin-left: 30px;
    }
  }

  .navbar-child-venues {
    padding: 70px 15px 10px 15px;
    width: 100%;
    display: block;
  }

  a.childVenuheaderbuttons {
    padding: 6px 10px 7px 10px !important;
  }
}

@media (min-width: 480px) and (max-width: 735px) {
  .header {
    width: 100%;
    padding-top: 64px;
  }

  .EventChildHeader {
    .lastEditeby {
      line-height: 12px;
    }
  }

  button.FaqPopupbtn {
    display: none;
  }

  nav.navbar {
    flex-wrap: nowrap;
  }

  .mobile-container {
    max-height: 76px;
    max-width: 100%;
  }

  .topnav {
    z-index: 10;
    position: fixed;
    width: 100%;
  }

  i.fa.fa-bars {
    font-size: 40px;
  }

  input.search {
    display: none;
  }

  button.Addnew {
    display: none;
  }

  i.fa-solid.fa-magnifying-glass {
    display: block;
    font-size: 23px;
    color: gray;
  }

  i.fa-regular.fa-plus {
    display: initial;
  }

  button.plusicon {
    display: block;
    background-color: #ff2851;
    color: white;
    border: none;
    font-size: 17px;
    margin-left: 9px;
  }

  p.plusicon {
    display: block !important;
  }

  .container {
    flex-wrap: nowrap !important;
    max-width: 100%;
  }

  span.sort {
    display: none;
  }

  .searchBar {
    justify-content: flex-end;
    display: flex;
  }

  span.by {
    margin-left: 12px;
  }

  a.active {
    padding: 14px 16px;
  }

  .dropdown.d-flex {
    margin: 0;
  }

  nav.navbar-child {
    padding: 21px 15px 10px 15px;
    width: 100%;
    display: block;

    a.navbarBrand-childeheaders {
      font-size: 23px !important;
    }

    .dropdown-childHeader.d-flex {
      display: block !important;

      select.selector-childHeader {
        width: 100%;
      }
    }

    .draft-public {
      margin-left: 30px;
    }
  }

  .navbar-child-venues {
    padding: 21px 15px 10px 15px;
    width: 100%;
    display: block;
  }

  nav.navbar {
    padding: 13px 25px 13px 15px;
  }
}

@media (min-width: 735px) and (max-width: 979px) {
  .header {
    width: 100%;
    padding-top: 64px;
  }

  nav.navbar {
    flex-wrap: nowrap;
  }

  .container-fluid {
    padding-left: 0;
    width: 100%;
  }

  .mobile-container {
    max-height: 76px;
    max-width: 100%;
  }

  .topnav {
    z-index: 10;
    position: fixed;
    width: 100%;
  }

  i.fa-regular.fa-plus {
    display: initial;
  }

  i.fa.fa-bars {
    font-size: 40px;
  }

  .container {
    flex-wrap: nowrap !important;
    max-width: 100%;
  }

  .searchBar {
    justify-content: flex-end;
    display: flex;
  }

  span.sort {
    margin-right: 4px;
    margin-left: 15px;
  }

  a.active {
    padding: 14px 16px;
  }

  .dropdown.d-flex {
    margin: 0;
  }

  nav.navbar-child {
    padding: 21px 15px 10px 15px;
    width: 100%;

    a.navbarBrand-childeheaders {
      font-size: 23px !important;
    }

    .dropdown-childHeader.d-flex {
      display: block !important;

      select.selector-childHeader {
        width: 100%;
      }
    }

    .draft-public {
      margin-left: 30px;
    }
  }

  .navbar-child-venues {
    padding: 21px 15px 10px 15px;
    width: 100%;
  }
}

@media (min-width: 979px) and (max-width: 1280px) {
  .container-fluid {
    padding-left: 0;
    width: 100%;
    margin-left: 0;
  }

  nav.navbar {
    flex-wrap: nowrap;
  }

  .mobile-container {
    max-height: 76px;
    max-width: 100%;
  }

  .topnav {
    z-index: 10;
    position: fixed;
    width: 100%;
  }

  i.fa.fa-bars {
    font-size: 40px;
  }

  .container {
    flex-wrap: nowrap !important;
    max-width: 100%;
  }

  .searchBar {
    justify-content: flex-end;
    display: flex;
  }

  span.sort {
    margin-right: 4px;
    margin-left: 15px;
  }

  a.active {
    padding: 14px 16px;
  }

  .dropdown.d-flex {
    margin: 0;
  }

  nav.navbar-child {
    padding: 10px 15px;
    width: 100%;

    a.navbarBrand-childeheaders {
      font-size: 23px !important;
    }

    .dropdown-childHeader.d-flex {
      display: block !important;

      select.selector-childHeader {
        width: 100%;
      }
    }

    .draft-public {
      margin-left: 30px;
    }
  }

  .navbar-child-venues {
    padding: 10px 15px 10px 15px;
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .PackageHeader {
    .content-Event {
      width: 100%;
    }
  }
  .applicantHeader {
    display: flex !important;
    .HeaderArchive button.ArchiveBtn {
      padding: 10px 20px;
      margin-right: 17px;
    }
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .PackageHeader {
    .content-Event {
      width: 99%;
    }
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .PackageHeader {
    .content-Event {
      width: 99%;
    }
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .PackageHeader {
    .content-Event {
      width: 99%;
    }
  }
}

@media (min-width: 1281px) {
  .content-Event {
    width: calc(100% - 21%);
  }

  .FaqHeader {
    width: 80%;
  }
}

#wrap {
  display: inline-block;
  float: right;
  padding: 0;
}

input.SearchInputMobile[type="text"] {
  height: 30px;
  font-size: 14px;
  display: inline-block;
  font-family: "Lato";
  font-weight: 100;
  border: none;
  outline: none;
  color: #555;
  padding: 3px;
  padding-right: 30px;
  width: 0px;
  position: absolute;
  top: 0;
  right: 30px;
  background: none;
  z-index: 3;
  transition: width 0.4s cubic-bezier(0, 0.795, 0, 1);
  cursor: pointer;
}

input.SearchInputMobile[type="text"]:focus:hover {
  border: 1px solid lightgray;
}

input.SearchInputMobile[type="text"]:focus {
  width: 275px;
  z-index: 1;
  border-bottom: 1px solid #ff0000;
  cursor: text;
}

input.SearchInputMobile[type="submit"] {
  height: 67px;
  width: 63px;
  display: inline-block;
  color: red;
  float: right;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAADNQTFRFU1NT9fX1lJSUXl5e1dXVfn5+c3Nz6urqv7+/tLS0iYmJqampn5+fysrK39/faWlp////Vi4ZywAAABF0Uk5T/////////////////////wAlrZliAAABLklEQVR42rSWWRbDIAhFHeOUtN3/ags1zaA4cHrKZ8JFRHwoXkwTvwGP1Qo0bYObAPwiLmbNAHBWFBZlD9j0JxflDViIObNHG/Do8PRHTJk0TezAhv7qloK0JJEBh+F8+U/hopIELOWfiZUCDOZD1RADOQKA75oq4cvVkcT+OdHnqqpQCITWAjnWVgGQUWz12lJuGwGoaWgBKzRVBcCypgUkOAoWgBX/L0CmxN40u6xwcIJ1cOzWYDffp3axsQOyvdkXiH9FKRFwPRHYZUaXMgPLeiW7QhbDRciyLXJaKheCuLbiVoqx1DVRyH26yb0hsuoOFEPsoz+BVE0MRlZNjGZcRQyHYkmMp2hBTIzdkzCTc/pLqOnBrk7/yZdAOq/q5NPBH1f7x7fGP4C3AAMAQrhzX9zhcGsAAAAASUVORK5CYII=)
    center center no-repeat;
  text-indent: -10000px;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  cursor: pointer;
  opacity: 0.4;
  cursor: pointer;
  transition: opacity 0.4s ease;
}

input.SearchInputMobile[type="submit"]:hover {
  opacity: 0.8;
}

@media (min-width: 736px) {
  .SearchFieldMobile {
    display: none;
  }
}

.SearchFieldMobile {
  position: absolute;
  width: 280px;
  background-color: #fff;
  border: 1px solid #ccc;
  right: 23px;
  top: 50px;
  display: flex;
  align-items: center;

  i.fa-solid.fa-magnifying-glass {
    position: absolute;
    left: 5px;
    z-index: 2;
    top: 10px;
  }

  input {
    width: 100%;
    position: relative;
    padding: 6px;
    padding-left: 30px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }
}
