.accordion-body-FaqChild {
  .ApplicentName {
    border: 1px solid #e9e9e9;
    margin: 10px;
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
    padding: 10px 5px;
    border-radius: 5px;
    min-height: 106px;
  }
  p.question-inputsbox {
    font-size: 18px;
    font-weight: 600;
    padding: 0 10px;
  }
  .DownloadResume {
    padding: 0 15px;
    margin-top: 2rem;
    margin-bottom: 1rem;
    a {
      text-decoration: none;
      margin-top: 10px;
      border: 1px solid #ff2851;
      color: #ffffff;
      padding: 10px 15px;
      background-color: #ff2851;
      font-weight: 600;
      border-radius: 5px;
      box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
    }
    a:hover {
      color: #ff2851;
      background-color: #ffffff;
      transition: 0.5s ease;
    }
    a:focus-visible {
      outline: none !important;
    }
  }
}
button.ArchiveBtn {
  margin: 0 15px;
  border: 0;
  background-color: #ff2851;
  color: #fff;
  font-size: 14px;
  font-weight: 800;
  padding: 13px 10px;
  width: max-content;
}
button.ArcBtn {
  margin: 0 15px !important;
}
@media (max-width: 768px) {
  .FaqModalTop {
    margin-top: 0 !important;
    .col-md-6 {
      padding: 0;
    }
    .ApplicentName {
      margin: 10px 0;
      .CommonLabel {
        font-size: 12px;
      }
      p.question-inputsbox {
        font-size: 15px;
      }
    }
    .DownloadResume {
      text-align: center;
    }
  }
  button.ArchiveBtn {
    padding: 9px 10px;
    margin-top: 10px;
  }
}
.archivedList {
  display: flex;
  .archivedName {
    width: 100%;
  }
  .archived_Button {
    display: flex;
    button {
      margin: 0 24px;
    }
  }
}
.NoDataFound {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 40vh);
}
