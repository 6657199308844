.banner-btn-inactive {
  display: flex;
  cursor: pointer;
  align-items: center;
  cursor: pointer;

  a.button-btn-btn-inactive {
    border: 1px solid black;
    background-color: #ff2851;
    padding: 10px 4px;
    text-decoration: none;
    margin: 0 12px;

    a.btn-icon-inactive {
      outline: 1px solid white;
      padding: 7px 12px 7px 7px;
      text-decoration: none;

      img {
        margin-right: 5px;
      }

      span.inactive {
        color: white;
      }
    }
  }

  .Delete-icone-Adbanner {
    margin-left: 11px;
  }
}

.banner-btn-nActive {
  display: flex;
  cursor: pointer;
  align-items: center;

  a.button-btn-btn-nActive {
    border: 1px solid black;
    background-color: #b4b4b4;
    padding: 10px 4px;
    text-decoration: none;
    margin: 0 12px;

    a.btn-icon-nActive {
      background-color: #b4b4b4;
      outline: 1px solid white;
      padding: 7px 20px 7px 9px;
      text-decoration: none;

      img {
        margin-right: 5px;
      }

      span.nActive {
        color: white;
      }
    }
  }
}

.Addadbanners {
  input.add-adbanner-title {
    width: 100%;
    padding: 8px 10px 8px 10px;
    margin-bottom: 13px;
  }

  input.add-adbanner-link {
    width: 100%;
    padding: 8px 10px 8px 10px;
    margin-bottom: 13px;
  }

  .uploadbtn {
    width: 100%;
    padding: 10px 10px 8px 10px;
    border: none;
    background-color: #ff2851;
    color: white;
    font-size: 18px;
    margin-top: 20px;
    font-weight: 600;
  }

  img.AddAdbannner-img {
    width: 100%;
    height: 185px;
    object-fit: cover;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .content-banner-list {
    .button-btn-btn-nActive {
      padding: 3px 4px 7px !important;

      .btn-icon-nActive {
        padding: 0px !important;
        padding-bottom: 5px !important;

        img {
          margin-left: 5px;
        }
      }

      span.nActive {
        display: none;
      }
    }
  }

  .button-btn-btn-inactive{
    padding: 3px 4px 7px !important;
    .btn-icon-inactive{
      padding: 0px !important;
      padding-bottom: 5px !important;
      img {
        margin-left: 5px;
      }
      span.inactive {
        display: none;
      }
    } 
  } 
}

@media (min-width: 768px) and (max-width: 992px) {}

@media (min-width: 992px) and (max-width: 1200px) {}