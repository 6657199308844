.citiesChild {

  label.Name {
    font-size: 14px;
    font-weight: 600;
    padding: 6px;
  }

  input.Timezone-name {
    width: 100%;
    border: 1px solid lightgray;
    padding: 8px 10px 8px 10px;
  }

  label.Timezone {
    font-size: 14px;
    font-weight: 600;
    padding: 6px;
    margin-top: 15px;
  }

  input.Timezone-Timezone {
    width: 100%;
    border: 1px solid lightgray;
    padding: 8px 10px 8px 10px;
  }
}

@media (min-width: 300px) and (max-width: 480px) {}

@media (min-width: 480px) and (max-width: 735px) {}

@media (min-width: 735px) and (max-width: 979px) {}

@media (min-width: 979px) and (max-width: 1280px) {}