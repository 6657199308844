.faq-child {
  .AccordinDiv {
    display: flex;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: #fff;
    padding: 10px;
    margin-bottom: 14px;
    .accordion {
      width: 100%;
    }
  }

  h2.HeadingPath.accordion-header {
    width: 100%;
    display: block;
    border: 1px solid lightgray;
    background-color: white;
  }

  // .accordion-collapse.collapse.show {
  // }

  .accordion-body {
    background-color: white;
    border: 1px solid lightgray;

    .accordion-body-FaqChild {
      margin-top: 15px;
    }
  }

  .accordion-item {
    padding: 0;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 8px;
  }

  .accordion-item.FaqChiledArea {
    border: 1px solid lightgray;
    margin-bottom: 12px;
    padding: 5px;
    background-color: white;
  }

  .accordion-header-FaqChilds {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 7px;
    margin-bottom: 0;
    background-color: white;
    width: 100%;
  }

  ::after {
    width: 0;
    height: 0;
    margin: 0;
  }

  button.accordion-button {
    font-size: 13px;
  }

  button.accordion-button.collapsed {
    img.downArrow {
      transform: rotate(360deg);
    }
  }

  button.delete-btn {
    border: none;
    background-color: white;
    width: max-content;
    .FAQDeletAlign {
      display: flex;
      align-items: center;
      img {
        position: relative;
        top: -2px;
      }
    }
  }

  a.Delete-faq {
    text-decoration: none;
    color: black;
  }

  button.accordion-button.collapsed {
    font-size: 13px;
  }

  span.delete-team-member {
    font-size: 13px;
    font-weight: 600;
    margin-left: 4px;
    font-family: lato;
    color: red;
  }

  img.downArrow {
    width: 13px;
    margin: 0 7px;
    transform: rotate(180deg);
  }

  button.accordion-button {
    box-shadow: none !important;
    background-color: white !important;
    padding: 0;
    color: #4a4a4a !important;
    font-family: Lato;
    font-size: 13px;
    font-weight: bold;
    line-height: 18px;
    cursor: pointer;
  }

  button.accordion-button.collapsed {
    padding: 0;
  }

  .del-buttons {
    margin-top: -10px;
  }
}

.hed-quest {
  display: flex;
  justify-content: end;

  label.airports-question {
    color: #000000;
    font-family: Lato;
    font-size: 13px;
    font-weight: 900;
  }

  button.update-btn {
    border: none;
    background-color: #ff2851;
    padding: 6px 30px 6px 30px;

    a.update-faq {
      text-decoration: none;
      color: white;
      font-weight: 600;
    }

    a.Delete-packages {
      text-decoration: none;
      color: white;
      font-weight: 600;
    }
  }
}

input.airports-qua {
  width: 100%;
  margin-top: 11px;
  padding: 8px 10px 8px 10px;
  border: 1px solid lightgray;
  font-size: 13px;
}

.answer-faq {
  padding-top: 25px;
}

label.Answer-question {
  color: #000000;
  font-family: Lato;
  font-size: 13px;
  font-weight: 900;
  margin-bottom: 10px;
}

textarea#floatingTextarea2 {
  padding-top: 12px;
  transition: none;
  border-radius: inherit;
  box-shadow: none;
  font-size: 13px;
}
@media (max-width: 768px) {
  .faq-child {
    .AccordinDiv {
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
    }
  }
}
@media (min-width: 300px) and (max-width: 480px) {
  .faq-child {
    span.delete-team-member {
      display: none;
    }
  }
}

button.accordion-button.collapsed.FaqDropDown {
  background-color: white !important;
}

@media (min-width: 480px) and (max-width: 735px) {
  .faq-child {
    span.delete-team-member {
      display: none;
    }
  }
}

@media (min-width: 735px) and (max-width: 979px) {
  .faq-child {
    span.delete-team-member {
      display: none;
    }
  }
}

@media (min-width: 979px) and (max-width: 1280px) {
}
