$fontsize: 13px;

.notification-container {
    width: 100%;
    padding: 4px 20px;
}
.notification-area {

  span.notification-heading {
    font-weight: 900;
    font-family: Lato;
    font-size: 12px;
  }

  textarea.form-control {
    height: 200px !important;
    margin-top: 13px;
    border-radius: inherit;
  }
  .button {
    margin-top: 16px;
  }
  button.Sent-btn.btn {
    border: 1px solid;
    background-color: #ff2851;
    color: white;
    text-align: center;
    width: 215px;
    border-radius: inherit;
    height: 46px;
    font-size: 20px;
    font-weight: 600;
  }
}
.history {
  margin: 20px 0;

  p.notification-heading {
    font-weight: 900;
    font-family: Lato;
    font-size: 12px;
  }
  span.heading {
    font-size: $fontsize;
  }

  .history-box {
    border: 1px solid #D7D7D7;
    padding: 15px;
    margin-bottom: 12px;
  }
  .history-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  span.Date\&time {
    font-size: 12px;
    font-weight: 700;
    font-style: italic;
  }
  span.android {
    font-size: $fontsize;
  }
  .view {
    font-size: $fontsize;
  }
  .iphone {
    font-size: $fontsize;
  }
  .iph-view {
    font-size: $fontsize;
  }
}
@media (min-width: 300px) and (max-width: 480px) {
  .notification-container {
    top: 135px;
    width: calc(100% - 0px);
    padding: 0 15px;
  }
}
@media (min-width: 480px) and (max-width: 1280px) {
  .notification-container {
    padding: 0 15px;
  }
}
@media (min-width: 735px) and (max-width: 979px) {
 
}
@media (min-width: 979px) and (max-width: 1280px) {
 
}
