.CategorySec {
  width: 97%;
  margin-left: auto;
  .Header {
    width: 100%;
    padding: 14px 55px 13px 0px;
    background-color: #fefefe;
    border-bottom: 1px solid lightgray;
    padding-left: 15px;
    a.navbarBrand {
      margin-left: 10px;
    }
    .categoryHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .CategoryHeaderBtn {
        .Addnew {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 5px 20px;
        }
      }
    }
  }
  .CategoryContent {
    margin: 15px;
    .CategoryDetails {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #e9e8e8;
      padding: 25px 30px 25px 20px;
      background-color: white;
      margin-bottom: 12px;
      .CategoryTitle {
        cursor: pointer;
      }
      .CategoryModalBtn {
        padding: 0;
        border: 0;
        &:focus-visible,
        :focus {
          outline: invert;
          border: 0;
          box-shadow: none;
        }
        .CategoryTitle {
          h3 {
            font-weight: 600;
            font-size: 22px;
            font-family: "Roboto Condensed";
            margin: 0;
          }
        }
      }
      .CategoryDeleteSec {
        .deleteText {
          font-size: 13px;
          font-weight: 600;
          color: #ff2851;
        }
      }
      button.EditButton {
        margin: 0 10px;
        padding: 3px 10px;
        background: #fff;
        color: #ff2851;
        font-weight: 500;
        border: 0;
        span.deleteIcon img {
          position: relative;
          top: -2px;
        }
      }
    }
    .categoryModal {
      .EditInput {
        width: calc(100% - 10%);
        padding: 8px;
      }
    }
  }
}
.modal-content {
  .modal-footer {
    .btnClose {
      background-color: dimgray !important;
    }
    .btnUpdate {
      background-color: #ff2851 !important;
    }
    .btnUpdate,
    .btnClose {
      color: #fff !important;
      padding: 7px;
      border-radius: 6px;
      border: 0 !important;
    }
  }
}

// Catregory Chil CSS

.CategoryChildSec {
  .CategorySec {
    width: 100%;
    .Header {
      .categoryHeader {
        justify-content: flex-start;
      }
    }
  }
  .CategoryChild {
    .name {
      label {
        font-size: 14px;
        font-weight: 600;
        padding: 6px;
      }
      .CategoryName {
        width: 100%;
        border: 1px solid lightgray;
        padding: 8px 10px 8px 10px;
      }
    }
  }
}

@media (max-width: 768px) {
  .CategorySec {
    .Header {
      padding: 0;
      .categoryHeader {
        .CategoryHeaderBtn {
          .Addnew {
            padding: 5px 11px;
            span {
              display: none;
            }
          }
          .modal-content {
            padding: 0px;
          }
        }
      }
    }
    .CategoryContent {
      .CategoryDetails {
        padding: 25px 15px;
      }
      .modal-content {
        padding: 0px;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 992px) {
}
@media (min-width: 992px) and (max-width: 1280px) {
}

@media (max-width: 1280px) {
  .CategorySec {
    width: 100%;
    .Header {
      margin-top: 75px;
      .categoryHeader {
        .CategoryHeaderBtn {
          .Addnew {
            display: block;
          }
        }
      }
    }
  }
}
