.faq {
  margin-bottom: 20px;
  margin-left: 20px;

  .faq-Box {
    border: 1px solid #e9e8e8;
    padding: 25px 0px 15px 0px;
    background-color: white;

    ul.content-item {
      list-style: none;
    }

    li.faq-faq {
      display: flex;
      justify-content: space-between;
    }

    .content-faq {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .heading-content-title {
      margin-left: 37px;
    }

    a.include-parking {
      text-decoration: none;
      color: black;
      font-size: 18px;
      font-weight: 700;

      .title {
        font-family: "Roboto Condensed";
        font-size: 22px;
        font-weight: bold;
      }
    }

    span.moveicon {
      font-size: 24px;
    }

    button.delete-btn {
      border: none;
      background-color: white;
      margin-right: 45px;
    }

    a.Delete-faq {
      text-decoration: none;
      color: red;
    }

    a.edit-faq {
      text-decoration: none;
      color: #4a88da;
    }

    span.delete-team-member {
      font-size: 13px;
      font-weight: 600;
      margin-left: 7px;
    }
  }
}

p.erroryet {
  margin-left: 14px;
}

div#contained-modal-title-vcenter {
  font-weight: 700;
  font-family: "Roboto Condensed";
  font-size: 20px;
}

.modal-content {
  width: 100%;
}

.section-Name {
  display: flex;

  p.section-title {
    margin-bottom: 0;
    margin-top: 8px;
    font-weight: 700;
  }

  input.section-input-fields {
    width: 78%;
    margin-left: 15px;
    padding: 8px 6px 8px 6px;
  }
}

.section-Name-child {
  display: flex;
  margin-top: 21px;

  p.section-title-child {
    margin-bottom: 0;
    margin-top: 8px;
    font-weight: 700;
  }

  input.section-input-fields-child {
    width: 78%;
    margin-left: 27px;
    padding: 8px 6px 8px 6px;
  }
}

button.faq-cancel-btn.btn.btn-primary {
  background-color: white;
  color: black;
  border: 1px solid lightgrey;
}

button.Faq-save-btn.btn.btn-primary {
  background-color: #3071a9;
  border-color: #285e8e;
}

.faqSec {
  width: 90%;
  margin: auto;
}

@media (min-width: 320px) and (max-width: 768px) {
  .section-Name {
    display: block;

    input.section-input-fields {
      width: 100%;
      margin-left: 0;
      margin: 10px 0;
    }
  }

  .FaqModalFooter {
    display: block;
    text-align: end;

    button {
      width: auto;
    }
  }
}

@media (min-width: 300px) and (max-width: 480px) {
  .Faq-container {
    top: 130px;
    width: calc(100% - 0px);
  }

  .faq {
    margin: 9px 18px 3px 19px;

    ul.content-item {
      padding-left: 9px;
    }

    span.delete-team-member {
      display: none;
    }

    button.delete-btn {
      margin-right: 8px !important;
    }

    .heading-content-title {
      margin-left: 16px !important;
    }
  }

  .FaqHeader {
    width: 100%;
  }
}

@media (min-width: 480px) and (max-width: 735px) {
  .faq {
    margin: 9px 18px 3px 19px;

    ul.content-item {
      padding-left: 9px;
    }

    span.delete-team-member {
      display: none;
    }

    button.delete-btn {
      margin-right: 8px !important;
    }

    .heading-content-title {
      margin-left: 16px !important;
    }
  }

  .FaqHeader {
    width: 100%;
  }
}

@media (min-width: 735px) and (max-width: 979px) {
  .faq {
    margin: 9px 18px 3px 19px;
  }
}

@media (min-width: 979px) and (max-width: 1280px) {
  .faq {
    margin: 9px 18px 3px 19px;
  }
}
