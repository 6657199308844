.packages-container {
  width: 100%;
  margin-top: 8px;
}
.packageHeaderAlign {
  display: flex;
}
.packages {
  padding: 0 30px;

  .packages-Box {
    border: 1px solid #e9e8e8;
    padding: 26px 8px 26px 8px;
    background-color: white;
    margin-bottom: 10px;

    ul.content-item {
      list-style: none;
      padding-left: 20px;
      margin-bottom: 0;
    }

    li.pakages-item {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
    }

    .content-Name {
      display: flex;
      align-items: center;
    }

    .heading-content-title {
      margin-left: 47px;
    }

    a.include-parking {
      text-decoration: none;
      color: black;
      font-size: 16px;
      font-weight: 700;
      font-family: "Roboto Condensed";
    }

    span.moveicon {
      font-size: 24px;

      i.fa-sharp.fa-solid.fa-ellipsis-vertical {
        margin-right: 4px;
      }
    }

    button.delete-btn {
      border: none;
      background-color: white;
      margin-right: 23px;
    }

    a.Delete-packages {
      text-decoration: none;

      img {
        margin-top: -5px;
      }
    }

    i.fa-solid.fa-trash-can {
      margin-right: 8px;
      font-size: 15px;
    }

    span.delete-team-member {
      font-size: 13px;
      font-weight: 600;
      margin-left: 7px;
    }
  }
}

@media (min-width: 300px) and (max-width: 480px) {
  .HideForm {
    display: none;
  }

  .packages {
    padding: 0px 10px;
    margin-bottom: 13rem;

    ul.content-item {
      padding-left: 9px;
    }

    span.delete-team-member {
      display: none;
    }

    button.delete-btn {
      margin-right: 8px !important;
    }

    .heading-content-title {
      margin-left: 16px !important;
    }
  }
}

@media (min-width: 480px) and (max-width: 735px) {
  .HideForm {
    display: none;
  }

  .packages-container {
    // padding-top: 93px;
    // width: calc(100% - 0px);
  }

  .packages {
    padding: 0px 10px;

    ul.content-item {
      padding-left: 9px;
    }

    span.delete-team-member {
      display: none;
    }

    button.delete-btn {
      margin-right: 8px !important;
    }

    .heading-content-title {
      margin-left: 16px !important;
    }
  }
}

@media (min-width: 735px) and (max-width: 979px) {
  .packages-container {
    // width: calc(100% - 0px);
  }
}

@media (min-width: 735px) {
  .form.HideForm {
    width: 50%;
  }
}
