.packages-child {
  padding: 10px;

  .CommonLabel {
    font-size: 14px;
    padding: 10px;
    color: #000;
    font-weight: bold;
    font-family: "Roboto Condensed";
    display: block;
  }

  label.parkings-child {
    font-size: 15px;
    font-weight: 600;
    padding: 6px;
  }

  input.parkingbook {
    width: 100%;
    border: 1px solid lightgray;
    padding: 8px 10px 8px 10px;
  }

  .active-radio-buttons {
    padding-top: 20px;

    span.file-type-text {
      display: flex;

      .form-check {
        display: flex;
        align-items: center;
      }

      .addparkingType {
        padding: 6px;
        font-size: 14px;
        font-weight: 500;
        margin-right: 0;
        display: flex;
        align-items: center;

        label {
          padding: 0 10px;
        }
      }
    }
  }

  .Editor-content {
    padding-top: 20px;
  }

  .tovenue {
    .tovenue-box {
      background-color: white;
      padding: 16px;
      margin-top: 25px;

      .RadioDiv {
        display: flex;
        align-items: flex-start;
        padding: 15px 0;
        margin-left: 2px;

        input {
          margin-right: 10px;
        }

        label {
          font-size: 14px;
          font-family: "Roboto", sans-serif;
        }
      }

      .form-group {
        display: flex;
        margin: 20px 0;

        input {
          margin-right: 10px;
        }

        label {
          font-family: "Roboto Condensed";
        }
      }

      label.Collins {
        font-size: 20px;
        font-weight: 600;
        font-family: "Roboto Condensed";
        margin-bottom: 10px;
      }
      .VenueRadioBtn {
        max-height: 300px;
        overflow: auto;

        &::-webkit-scrollbar {
          width: 5px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #c9c9c9;
        }
      }
    }
  }

  .form-check.side-content {
    margin-top: 20px;
  }
}

.radio-item {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  margin: 10px 0 0;
}

.radio-item input[type="radio"] {
  display: none;
}

.radio-item label {
  font-weight: normal;
  font-size: 14px;
  color: #000;
  font-family: "Roboto Condensed";
}

.radio-item label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  border-radius: 1px;
  border: 2px solid black;
  background-color: transparent;
}

.radio-item input[type="radio"]:checked + label:after {
  border-radius: 1px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 10px;
  content: " ";
  display: block;
  background: #4cb8dc;
}

.paymentstypebox {
  .form-group {
    display: block;
    margin-bottom: 15px;
    font-size: 14px;
    font-family: "Roboto Condensed";
  }

  .form-group input {
    padding: 0;
    display: none;
    cursor: pointer;
  }

  .form-group label {
    position: relative;
    cursor: pointer;
    margin-top: 20px;
  }

  .form-group label:before {
    content: "";
    border: 2px solid black;
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 10px;
  }

  .form-group input:checked + label:after {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    left: 5px;
    border: 0px solid #4cb8dc;
    border-width: 7px;
  }
}

@media (min-width: 300px) and (max-width: 480px) {
  .packages-child {
    // padding-top: 126px;
    // width: calc(100% - 0px);

    label.parkings-child {
      font-size: 13px !important;
    }

    label.addparkingType {
      font-size: 13px !important;
    }

    label.packagesChi {
      font-size: 13px;
    }

    label.parkingChi {
      font-size: 13px;
    }

    label.Collins {
      font-size: 18px !important;
    }

    label.form-check-label {
      font-size: 13px;
    }
  }
}

@media (min-width: 480px) and (max-width: 735px) {
  .packages-child {
    // padding-top: 126px;
    // width: calc(100% - 0px);
  }
}

@media (min-width: 735px) and (max-width: 979px) {
  .packages-child {
    // padding-top: 126px;
    // width: calc(100% - 0px);
  }
}

@media (min-width: 979px) and (max-width: 1280px) {
  .packages-child {
    // padding-top: 126px;
    // width: calc(100% - 0px);
  }
}
